interface mensajeError{
    [key: string]: string;
}

const mensajesDeError: mensajeError={
    required: 'Este campo es requerido*',
    pattern: 'El correo electronico proporcionado debe ser valido*',
    minlength: 'Este campo debe ser minimo de 8 caracteres*',
    maxlength:'Este campo solo puede tener un maximo de 10 caracteres*', 
};

export function mensajeErrrorValidador(nombreValidador: string): string{
    return mensajesDeError[nombreValidador] ?? 'Algo va mal';
}