import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl } from '@angular/forms';
import { mensajeErrrorValidador } from './validador';

@Component({
  selector: 'app-mensaje-error',
  template: `
   
    <div class="d-flex align-items-center font-weight-medium text-danger text-sm mt-1 ml-1">
      {{mensajeError}}
    </div>

  `,
})
export class mensajeErrorComponent {

  //Aqui con ayuda del input determinamos que tipo de mensaje es el que se va a mandar en el error 
  @Input() control!:AbstractControl;

  get mensajeError():string{
    const error = this.control?.errors;
    const nombreValidador = Object.keys(error ?? {})[0];
    return this.control.touched && nombreValidador
      ?mensajeErrrorValidador(nombreValidador)
      :'';
  }
}
