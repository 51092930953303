import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { AgregarComponent, DataDialog } from '../agregar/agregar.component';
import { DeleteComponent } from '../../dialogs/delete/delete.component';

@Component({
  selector: 'app-articulo',
  templateUrl: './articulo.component.html',
  styleUrls: ['./articulo.component.css']
})
export class ArticuloComponent implements OnInit {

  @Input()
  dataDialog: DataDialog = {
    articulo: {
      nombre: '',
      precio: 0,
      imagen: '',
      autor: '',
      link: ''
    },
    id: '',
    imgUrl: ''
  };
  @Output()
  newItemEvent = new EventEmitter<{dataDialog: DataDialog, file: File}>();

  @Output()
  deleteEvent = new EventEmitter<DataDialog>();
  imgUrl = '';
  paginaPropia = false;

  constructor(private imageServices: ImagenesService, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.paginaPropia = this.dataDialog.articulo.autor === sessionStorage.getItem('email') as string;
    this.imageServices.obtenerImagenByPath(this.dataDialog.articulo.imagen).subscribe((response) => {
      this.imgUrl = response;
    });
  }

  navigate(url: string): void {
    this.router.navigate([url]);
  }

  emitEvent(dataDialog: DataDialog, file: File) {
    this.newItemEvent.emit({dataDialog: dataDialog, file: file});
  }

  update(): void {
    let config: MatDialogConfig = new MatDialogConfig();
    this.dataDialog.imgUrl = this.imgUrl;
    config.data = this.dataDialog;
    const dialogRef = this.dialog.open<AgregarComponent, MatDialog>(AgregarComponent, config);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
        this.dataDialog = result.dataDialog;
        this.emitEvent(this.dataDialog, result.imagen);
      }
    });
  }

  delete(): void {
    let config: MatDialogConfig = new MatDialogConfig();
    config.data = "Eliminar Artículo";
    const dialogRef = this.dialog.open<DeleteComponent, string>(DeleteComponent, config);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteEvent.emit(this.dataDialog);
      }
    });
  }

}
