import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from 'src/app/models/usuario';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { QueryServiceService } from 'src/app/services/query-service.service';
import { DonacionComponent } from './donacion/donacion.component';
import { StripeService } from 'src/app/services/stripe.service';
import { CardsService } from 'src/app/services/cards.service';
import { CardData } from 'src/app/models/cardData';
import Swal from 'sweetalert2';
import { InsigniaService } from 'src/app/services/insignia.service';
import { InsigniaUsuario } from 'src/app/models/insignia-usuario';
import { Donacion } from 'src/app/models/donacion';
import { Saldo } from 'src/app/models/saldo';
import { Location } from '@angular/common';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-donaciones',
  templateUrl: './donaciones.component.html',
  styleUrls: ['./donaciones.component.css']
})
export class DonacionesComponent implements OnInit {

  usuario: Usuario = {
    nombre: '',
    apellido: '',
    email: '',
    descripcion: '',
    rol: [],
    categorias: [],
    facebook: '',
    instagram: '',
    twitter: ''
  }

  userId = '';
  userImgURL:string = '';
  portadaImgUrl: string = '';
  paginaPropia = false;

  cardData: CardData = {
    nombre: '',
    cardNumber: '',
    clabe: ''
  }

  insigniaUrl = '';
  insigniaName = 'donacion.png';

  donacionAsignada = false;

  constructor(private emailServices: EmailService,private imagenServices: ImagenesService, private _Activatedroute: ActivatedRoute, private queryServices: QueryServiceService, private dialog: MatDialog, private stripeServices: StripeService, private _location: Location, private insigniasServices: InsigniaService) {}

  ngOnInit(): void {
    this._Activatedroute.queryParams.subscribe((params) => {
      this.userId = atob(params['id']);
      this.paginaPropia = this.userId === sessionStorage.getItem('email') as string;
      this.getUsuarioData(this.userId);
      this.getImagen(this.userId, 'portada');
      this.getImagen(this.userId, 'perfil');
      this.getImagen(this.insigniaName, 'insignia');
      this.insigniasServices.consultarInsigniasPorUsuario(sessionStorage.getItem('email') as string).subscribe((res) => {
        res.forEach((doc) => {
          if ((doc.data() as InsigniaUsuario).insignia === this.insigniaName) {
            this.donacionAsignada = true;
          }
        });
      });
    });

  }

  backClicked() {
    this._location.back();
  }

  getImagen(userId: string, coleccion: string): void {
    this.imagenServices.obtenerImagen(userId, coleccion).subscribe((response) => {
      switch (coleccion) {
        case 'portada':
          this.portadaImgUrl = response;
          break;
        case 'perfil':
          this.userImgURL = response;
        break;
        default:
          this.insigniaUrl = response;
          break;
      }
    });
  }

  getUsuarioData(userId: string): void {
    this.queryServices.getUsuario(userId).subscribe((resp) => {
      resp.forEach((row) => {
        this.usuario = row.data() as Usuario;
      });
    });
  }

  openDialog(monto: number): void {
    let config: MatDialogConfig = new MatDialogConfig();
    config.data = monto;
    const dialogRef = this.dialog.open<DonacionComponent, number>(DonacionComponent, config);
    dialogRef.afterClosed().subscribe((result) => {

      if (result) {
        this.stripeServices.checkOut(result.monto * 100, 'mxn', result.token.id, this.userId, this.userId).subscribe((res: any) => {
          this.stripeServices.capture(res.id).subscribe((billed) => {

            const donacion: Donacion = {
              emisor: sessionStorage.getItem('email') as string,
              destinatario: this.userId,
              monto: String(result.monto),
              mensaje: result.mensaje,
              fechaTransaccion: new Date().toLocaleDateString('en-GB')
            };

            this.stripeServices.guardarDonacion(donacion).subscribe((res) =>{});

            this.stripeServices.getSaldo(this.userId).subscribe((response) => {

              let saldo: Saldo = {
                usuario: this.userId,
                monto: ''
              }
              if (response.data()) {
                const currentSaldo: Saldo = response.data() as Saldo;
                saldo.monto = String(Number(currentSaldo.monto) + Number(result.monto));
              } else {
                saldo.monto = String(result.monto);
              }

              this.stripeServices.saveSaldo(saldo).subscribe();

              this.enviarEmailDonacion(this.userId, String(result.monto),result.mensaje);

            });

            if (!this.donacionAsignada) {
              this.asignarInsignia();
            } else {
              Swal.fire({
                icon: 'success',
                title: '¡Gracias por tu apoyo!',
                confirmButtonText: 'Continuar'
              })
            }
          } , (error) => {
            this.stripeServices.refound(res.id).subscribe((err) => {
              console.log(err);
            });
          })
        });
      }
    });
  }

  asignarInsignia(): void {
    const insigniaFundador: InsigniaUsuario = {
      insignia: this.insigniaName,
      usuario: sessionStorage.getItem('email') as string,
      date: new Date()
    }
    this.insigniasServices.asignarInsignia(insigniaFundador).subscribe((res) => {
      this.donacionAsignada = true;
      Swal.fire({
        title: '¡Felicidades!',
        imageUrl: this.insigniaUrl,
        imageWidth: 250,
        imageHeight: 250,
        imageAlt: 'Custom image',
        confirmButtonText: 'Continuar',
        html:
        '<p>Has conseguido la insignia Primer Mecenas.</p>' +
      "<div class='firework' id='firework1'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>" +
    "<div class='firework' id='firework2'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>" +
    "<div class='firework' id='firework3'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>" +

    "<div class='firework' id='firework4'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>" +
    "<div class='firework' id='firework5'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>" +
    "<div class='firework' id='firework6'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>" +
    "<div class='firework' id='firework7'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>"
      });
    });
  }

  enviarEmailDonacion(destinatario:string, cantidad: string, mensaje: string):void{
    const body =  "<div style='background: white;'>" +
                  "<p>Haz recibido la cantidad de: " +cantidad+ " MXN</p>"+
                  "<p>"+mensaje+"</p>"
                  "</div>";
    this.emailServices.enviarEmail("Haz recibido una donacion nueva", destinatario, body).subscribe((result)=>{
      console.log(result);

    });
  }



}
