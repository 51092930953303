import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuariosServicesService } from 'src/app/services/usuarios.services.service';
import { selectUser, selectUserNombre } from '../utils/reduce/auth.selectors';
import { Usuario } from 'src/app/models/usuario';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserActions } from '../utils/reduce/auth.actions';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { SubirPostComponent } from './subir-post/subir-post.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  // userName$!: Observable<String>;
  user$!: Observable<Usuario>;

  constructor(private servicio: UsuariosServicesService, private router: Router, private store: Store, private imagenServices: ImagenesService, private dialog: MatDialog) {
    // this.userName$ = this.store.select(selectUserNombre);
    this.user$ = this.store.select(selectUser);
  }

  cerrarSesion(){
    this.servicio.cerrarSesion().subscribe((response)=>{
      this.store.dispatch(UserActions.saveUsuario({usuario: {
        nombre: '',
        apellido: '',
        email: '',
        descripcion: '',
        rol: [],
        categorias: [],
        facebook: '',
        instagram: '',
        twitter: ''
      }}));
      sessionStorage.clear();
      this.router.navigate(['login']);

    });
  }

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open<SubirPostComponent>(SubirPostComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        Swal.fire({
          icon: 'success',
          title: 'Gracias por aportar nuevo contenido',
          text: '',
          confirmButtonText: 'Continuar'
        });
      }
    });
  }

}
