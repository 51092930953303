import { Injectable } from '@angular/core';
import { Firestore, collection, doc, getDoc, setDoc } from '@angular/fire/firestore';
import { from } from 'rxjs';
import { CardData } from '../models/cardData';

@Injectable({
  providedIn: 'root'
})
export class CardsService {

  constructor( private firestore: Firestore) { }

  public saveCardData(cardData: CardData, email: string){
    const referencia = doc(collection(this.firestore,'card-data'), email);
    return from (setDoc(referencia,cardData));
  }

  public getCardData(email: string){
    return from (getDoc(
      doc(this.firestore, "card-data", email)
    ));
  }

}
