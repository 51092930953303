
<div class="row justify-content-around">
  <div class="col-1" (click)="navegar('/creativo',toBase64(usuario.email))">
    <div class="" *ngFor="let categoria of usuario.categorias">
      <img *ngIf="categoria == 1" class="circle-little-icon margin-05-rem" src="assets/icons/musica.svg"/>
      <img *ngIf="categoria == 2" class="circle-little-icon margin-05-rem" src="assets/icons/cine.svg"/>
      <img *ngIf="categoria == 3" class="circle-little-icon margin-05-rem" src="assets/icons/literatura.svg"/>
      <img *ngIf="categoria == 4" class="circle-little-icon margin-05-rem" src="assets/icons/desing.svg"/>
      <img *ngIf="categoria == 5" class="circle-little-icon margin-05-rem" src="assets/icons/gastronomia.svg"/>
      <img *ngIf="categoria == 6" class="circle-little-icon margin-05-rem" src="assets/icons/artesania.svg"/>
      <img *ngIf="categoria == 7" class="circle-little-icon margin-05-rem" src="assets/icons/arte_digital.svg"/>
    </div>
  </div>

  <div class="col-10 ">
    <div class="div-foto d-flex justify-content-center" (click)="navegar('/creativo',toBase64(usuario.email))">
      <div class="">
        <img class="foto-perfil-mini margin-1-rem" *ngIf="!userImgURL" src="assets/icons/usuario.svg" />
        <img class="foto-perfil-mini margin-1-rem" *ngIf="userImgURL" [src]="userImgURL" alt="Imagen de perfil" />
      </div>
    </div>

    <div class="div-texto row justify-content-center text-center" (click)="navegar('/creativo',toBase64(usuario.email))">
      <div class="col-12" >
        <p class="h5">
          {{(usuario.nombre + " "  + usuario.apellido).slice(0, 16)}}
        </p>
      </div>
      <div class="d-flex col-md-11 col-lg-10 justify-content-center">
        <p class="text-center">{{usuario.descripcion}}</p>
      </div>
    </div>

    <div class="row d-flex justify-content-center div-redes">

      <div class="col-2">
        <a href="{{usuario.instagram}}" target="_blank" *ngIf="usuario.instagram">
          <img  class="circle-little-icon" src="assets/icons/insta.svg"/>
        </a>
      </div>
      <div class="col-2" >
        <a href="{{usuario.twitter}}" target="_blank" *ngIf="usuario.twitter">
          <img class="circle-little-icon" src="assets/icons/twitter.svg"/>
        </a>
      </div>
      <div class="col-2">
        <a href="{{usuario.facebook}}" target="_blank" *ngIf="usuario.facebook">
          <img class="circle-little-icon" src="assets/icons/facebook.svg"/>
        </a>
      </div>

    </div>
  </div>
  <div class="col-1"></div>
</div>


