<div class="row full">

    <div class="col-12 d-flex justify-content-center margin-05-rem">
      <h1 *ngIf="dataDialog.evento.titulo === ''">Nuevo Evento</h1>
      <h1 *ngIf="dataDialog.evento.titulo !== ''">Editar Evento</h1>
    </div>

    <form [formGroup]="form">

      <div class="col-12 d-flex justify-content-center  margin-05-rem">

        <mat-form-field>
          <mat-label>Titulo</mat-label>
          <input matInput placeholder="Titulo del evento" value="" formControlName="titulo">
        </mat-form-field>

      </div>

    </form>

    <div style="padding-top: 20px;">

      <div *ngIf="image" class="d-flex justify-content-center">
        <div class="imagen">
          <img class=" img" [src]="image" alt="Imagen de perfil" />
        </div>
      </div>

      <div *ngIf="image ===''" (click)="uploadImg.click()" class="col-12 d-flex justify-content-center martin-top-20  media-container margin-auto">
        <input hidden type="file" #uploadImg accept=".png, .jpg , .jpeg"  (change)="subirImagen($event)">
        <button mat-button color="primary" style="height: 100%; width: 100%;" aria-label="Agregar foto">Cargar</button>
      </div>

    </div>

    <div class="col-12  d-flex justify-content-center" style="height: 36px;">
      <input hidden type="file" #uploadImg accept=".png, .jpg , .jpeg"  (change)="subirImagen($event)">
      <button  *ngIf="image !==''" mat-button color="primary" aria-label="Agregar foto" (click)="uploadImg.click()">Cargar</button>
    </div>

    <div class="col-12 d-flex justify-content-center margin-05-rem">
      <div class="margin-1-rem">
        <button mat-button color="warn" (click)="onNoClick(false)">Cancelar</button>
      </div>

      <div class="margin-1-rem">
        <button mat-raised-button color="accent" [disabled]="form.invalid || image === ''" (click)="onNoClick(true)">Publicar</button>
      </div>
    </div>
  </div>
