import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Evento } from 'src/app/models/evento';

export interface EventoDialog {
  evento: Evento;
  imgUrl: string;
  id: string;
 }

@Component({
  selector: 'app-agregar-evento',
  templateUrl: './agregar-evento.component.html',
  styleUrls: ['./agregar-evento.component.css']
})
export class AgregarEventoComponent implements OnInit {

  image: string | SafeUrl = '';
  form: FormGroup = new FormGroup({});
  editado = false;
  file: File | undefined;

  constructor(
    public dialogRef: MatDialogRef<AgregarEventoComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public dataDialog: EventoDialog
    ) { }

  ngOnInit(): void {
    if(this.dataDialog){
      console.log(this.dataDialog)
      this.image = this.dataDialog.imgUrl;
      this.form = new FormGroup({
        titulo: new FormControl({value: this.dataDialog.evento.titulo, disabled: false})
      });
    }else{
      this.dataDialog={
        evento:{
          titulo:'',
          fechaCreacion: new Date(),
          urlImagen:'',
          autor:''
        },
        imgUrl:"",
        id:""
      }
      this.form = new FormGroup({
        titulo: new FormControl('', [Validators.required])
      });
    }

  }

  onNoClick(value: boolean): void {
    if (value) {
      this.dataDialog.evento.titulo = this.form.controls['titulo'].value;
      this.dataDialog.evento.autor = sessionStorage.getItem('email') as string;
      this.dialogRef.close({dataDialog:
        {
        evento: this.dataDialog.evento,
        imgUrl: this.dataDialog.imgUrl,
        id: this.dataDialog.id
       },imagen:this.file
      });
    } else {
      this.dialogRef.close(value);
    }
  }

  subirImagen($event: any){
    this.file = $event.target.files[0];
    this.image = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL($event.target.files[0])
    );
  }



}
