import { Injectable } from '@angular/core';
import { Firestore, collection, doc, getDoc, getDocs, query, setDoc, where } from '@angular/fire/firestore';
import { from } from 'rxjs';
import { Donacion } from '../models/donacion';
import { Saldo } from '../models/saldo';
import { HttpClient } from '@angular/common/http';
const stripe = require('stripe')('sk_test_51NsDv9H6CPbpfsvgFA2tdVBwxBPBzNiJlpccT16QuZaPcVh6DxERiacsJBd0yKvnbLc4o2VDC9yDBiTUB1HNIbsV00qGbDzwh4');

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(private firestore: Firestore)  { }

  public guardarDonacion(donacion: Donacion){
    const referencia = doc(collection(this.firestore,`donaciones`));
    return from (setDoc(referencia, donacion));
  }


  public getDonaciones(email: string){
    return from (
      getDocs(
        query(collection(this.firestore, "donaciones"), where("emisor", "==", email))
      )
    );
  }

  public saveSaldo(saldo: Saldo){
    const referencia = doc(collection(this.firestore,`saldo`), saldo.usuario.toUpperCase());
    return from (setDoc(referencia, saldo));
  }

  public getSaldo(email: string){
    return from (getDoc(
      doc(this.firestore, "saldo", email.toUpperCase())
    ));
  }

  checkOut(amount: number, currency: string, source: string, description: string, email: string) {
    return from( stripe.charges.create({
      amount: amount,
      currency: currency,
      source: source,
      capture: false,
      description: description,
      receipt_email: email
    }));
  }

  capture(id: string) {
    return from(stripe.charges.capture(id));
  }

  refound(id: string) {
    return from( stripe.refunds.create({charge: id}))
  }

 

}
