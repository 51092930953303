
<google-map
  #myGoogleMap

  [width]="width" [height]="height"
  [center]="center"
  [options]="options"
  (mapClick)="eventHandler($event,'mapClick')"
  (mapDblclick)="eventHandler($event,'mapDblclick')"

>
  <map-marker
    #markerElem="mapMarker"
    *ngFor="let marker of markers"
    [position]="marker.position"
    [label]="marker.label"
    [title]="marker.title"
    [options]="marker.options"
    (mapClick)="openInfo(markerElem, marker.info)"

  >
  <map-info-window>
    <div style="width: 90%;  margin: auto;">
      <app-resume-card [usuario]="usuario"></app-resume-card>
    </div>

      </map-info-window>
  </map-marker>


</google-map>

<div *ngIf="mode === 'EDIT'">
  <div class="margin-1-rem">
    <button mat-button class="btn institutional-button" (click)="guardarDireccion()">Guardar Dirección</button>
  </div>
</div>




