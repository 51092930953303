<div>
  <div class="col-12  d-flex justify-content-center ">
    <h3>
      {{titulo}}
    </h3>
  </div>

  <div class="col-12 d-flex justify-content-center margin-05-rem">
    <div class="margin-1-rem">
      <button mat-raised-button color="warn" (click)="onNoClick(false)">Cencelar</button>
    </div>

    <div class="margin-1-rem">
      <button mat-raised-button color="accent" (click)="onNoClick(true)">Eliminar</button>
    </div>
  </div>
</div>



