import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  form: FormGroup = new FormGroup({});
  private readonly formBuilder = inject(FormBuilder);

  constructor(private emailServices: EmailService) { }

  ngOnInit(): void {
    this.inicializarForm();
  }

  inicializarForm():void{
    this.form = this.formBuilder.group({
      nombre:['',[Validators.required]],
      email:['',[Validators.required]],
      telefono:['',[Validators.maxLength(10), Validators.required]],
      asunto:['',[Validators.required]],
      mensaje:['',[Validators.required]]

    });
  }

  enviarEmail():void{
    const body =  "<div style='background: white;'>" +
                  "<p> Asunto: " +  this.form.controls['asunto'].value+ "</p>" +
                  "<p> Nombre: " +  this.form.controls['nombre'].value+ "</p>" +
                  "<p> Correo: " +  this.form.controls['email'].value+ "</p>" +
                  "<p> Telefono: " + this.form.controls['telefono'].value+ " </p>" +
                  "<p> Mensaje: " + this.form.controls['mensaje'].value+" </p>" +                
                  "</div>";
    this.emailServices.enviarEmail(this.form.controls['asunto'].value, "pruebacreatopolis@gmail.com", body).subscribe((result)=>{
      console.log(result);
      Swal.fire({
        title: '¡Correo enviado!',
        imageAlt: 'Custom image',
        confirmButtonText: 'Continuar',
        html:
        '<p>Nos pondremos en comunicacion contigo lo mas pronto posible para resolver tu problema.</p>' 
      });
    });
    this.form.reset();
  }

}
