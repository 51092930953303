<div class="row text-center" style="width: 500px;">

  <div class="col-12">
    <p class="h2">¡Felicidades!</p>
  </div>
  <div class="col-12">
    <p class="h5">
      Acabas de desbloquear la insignia {{insigniaTittle}}
    </p>
  </div>

  <div class="col-12">
    <div class="col-12 d-flex justify-content-center" >
      <img class="col-8 circle " src="assets/icons/usuario.svg" />
    </div>
  </div>

  <div class="col-12 d-flex justify-content-center" style="margin-top: 30px;">
    <div class="col-6">
      <button class="btn institutional-button col-12" mat-button (click)="close()">Aceptar  </button>
    </div>
  </div>

</div>
