import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, take, tap } from 'rxjs';
import { UsuariosServicesService } from '../services/usuarios.services.service';


export const EditGuardia = () =>  {
  const servicios = inject (UsuariosServicesService);
  const router = inject(Router);

  return servicios.userState$.
  pipe(
    take(1),
    tap((isLoggedIn)=>(!!isLoggedIn ?true :router.navigate(['login'])))
  )
};
