import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Evento } from 'src/app/models/evento';

@Component({
  selector: 'app-editar-evento',
  templateUrl: './editar-evento.component.html',
  styleUrls: ['./editar-evento.component.css']
})
export class EditarEventoComponent implements OnInit {

  image: string | SafeUrl = '';
  form: FormGroup = new FormGroup({});
  selected: Date | null | undefined;

  evento: Evento={
    titulo: '',
    fechaCreacion: new Date(),
    urlImagen: '',
    autor: ''
  }

  file: File | undefined; 

  constructor() { }

  ngOnInit(): void {
  }

}
