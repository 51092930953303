import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Show } from 'src/app/models/show';

export interface ShowDialog {
  show: Show;
  imgUrl: string;
  id: string;
 }



@Component({
  selector: 'app-agregar-asistir',
  templateUrl: './agregar-asistir.component.html',
  styleUrls: ['./agregar-asistir.component.css']
})
export class AgregarAsistirComponent implements OnInit {


  displayedColumns: string[] = ['Hora'];
  dataSource: any[] = [];

  startDate = new Date;
  image: string | SafeUrl = '';
  form: FormGroup = new FormGroup({});
  editado = false;
  file: File | undefined;
  opciones: string[] = [];


  events: string[] = [];

  addHorario() {
    const event = this.form.controls['horariosH'].value+[':']+this.form.controls['horariosM'].value+[' ']+this.form.controls['horariosT'].value;
    this.events.push(event);
    this.dataSource = [... this.dataSource, {Hora: event}]
    this.form.controls['horariosH'].setValue("");
    this.form.controls['horariosM'].setValue("");
    this.form.controls['horariosT'].setValue("");
  }

  constructor(
    public dialogRef: MatDialogRef<AgregarAsistirComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public dataDialog:ShowDialog
  ) {
    for (let i = 0; i < 60; i++) {
      const opcion = i < 10 ? `0${i}` : `${i}`;
      this.opciones.push(opcion);
    }

  }

  ngOnInit(): void {
    if(this.dataDialog){
      this.image = this.dataDialog.imgUrl;
      this.form = new FormGroup({
        titulo: new FormControl({value: '', disabled: false}),
        fechasEventosInicio: new FormControl({value: '', disabled: false}),
        fechasEventosFinal: new FormControl({value: '', disabled: false}),
        horariosH: new FormControl({value: '', disabled: false}),
        horariosM: new FormControl({value: '', disabled: false}),
        horariosT: new FormControl({value: '', disabled: false}),
        boletera: new FormControl({value: '', disabled: false})
      });

    }else{
      this.dataDialog={
        show:{
          titulo:'',
          fechaCreacion: new Date().toLocaleDateString('en-GB'),
          urlImagen:'',
          autor:'',
          horarios:[],
          fechaEventoInicio: '',
          fechaEventoFinal: '',
          boleteraUrl: ''
        },
        imgUrl:"",
        id:""
      }
      this.form = new FormGroup({
        titulo: new FormControl('', [Validators.required]),
        fechasEventosInicio: new FormControl('', [Validators.required]),
        fechasEventosFinal: new FormControl('', [Validators.required]),
        horariosH: new FormControl(''),
        horariosM: new FormControl(''),
        horariosT: new FormControl(''),
        boletera: new FormControl('', [Validators.required])

      });
    }
  }

  onNoClick(value: boolean): void {
    if (value) {
      this.dataDialog.show.titulo = this.form.controls['titulo'].value;

      this.dataDialog.show.fechaEventoInicio =  new Date(this.form.controls['fechasEventosInicio'].value).toLocaleDateString('en-GB')
      this.dataDialog.show.fechaEventoFinal =  new Date(this.form.controls['fechasEventosFinal'].value).toLocaleDateString('en-GB')
      this.dataDialog.show.horarios = this.events;
      this.dataDialog.show.autor = sessionStorage.getItem('email') as string;
      this.dataDialog.show.boleteraUrl =this.form.controls['boletera'].value;
      this.dialogRef.close({dataDialog:
        {
        show: this.dataDialog.show,
        imgUrl: this.dataDialog.imgUrl,
        id: this.dataDialog.id
       },imagen:this.file
      });
    } else {
      this.dialogRef.close(value);
    }
  }


  subirImagen($event: any){
    this.file = $event.target.files[0];
    this.image = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL($event.target.files[0])
    );
  }




}
