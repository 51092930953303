import { Injectable } from '@angular/core';
// import { Storage, ref, uploadBytes } from '@angular/fire/storage';
import { Observable, from } from 'rxjs';

import { getDownloadURL, getStorage, ref, uploadBytes, deleteObject, listAll } from "firebase/storage";

@Injectable({
  providedIn: 'root'
})
export class ImagenesService {

  constructor() { }

  public subirImagen(file: File, fileName: string, coleccion: string){
    const storage = getStorage();
    const storageRef = ref(storage, `${coleccion}/${fileName}`);
    return from(uploadBytes(storageRef, file));
  }


  public deleteImagen(dir: string){
    const storage = getStorage();
    const storageRef = ref(storage, `${dir}`);
    return from(deleteObject(storageRef));
  }

  public obtenerImagen(fileName: string, coleccion: string) {
    const storage = getStorage();
    const storageRef = ref(storage, `${coleccion}/${fileName}`);
    return from (getDownloadURL(storageRef));
  }

  public obtenerImagenByPath(path: string) {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}`);
    return from (getDownloadURL(storageRef));
  }


  public listAll(path: string) {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}`);
    return from (listAll(storageRef));
  }

}



