<div style="height: 50px;">

</div>


<div class="position-relative col-12 div-center" style="padding-bottom: 180px; ">
  <div class="position-absolute portada">
    <img *ngIf="portadaImgUrl" [src]="portadaImgUrl"/>
  </div>

  <div class="return" (click)="backClicked()">
    <a>Regresar</a>
  </div>

  <div class="position-absolute col-11 portada-padding">

    <div class="col-lg-12 col-md-11 justify-content-center" style="padding-bottom: 3rem;">

      <div class="row d-flex justify-content-center">
        <div class="col-lg-2 col-md-5 div-center-on-small" style="height: 14vw;">
          <img class="foto-perfil" *ngIf="!userImgURL" src="assets/icons/usuario.svg" />

          <img class="foto-perfil" *ngIf="userImgURL" [src]="userImgURL" alt="Imagen de perfil"/>
        </div>

        <div class="col-lg-4 col-md-5">

          <div>
            <p class="h2" style="color: white;">{{usuario.nombre}} {{usuario.apellido}}</p>
          </div>
          <div class="row col-8">
            <div class="col-md-2 col-lg-3" *ngFor="let categoria of usuario.categorias">
              <img *ngIf="categoria == 1" class="circle col-12" src="assets/icons/musica.svg"/>
              <img *ngIf="categoria == 2" class="circle col-12" src="assets/icons/cine.svg"/>
              <img *ngIf="categoria == 3" class="circle col-12" src="assets/icons/literatura.svg"/>
              <img *ngIf="categoria == 4" class="circle col-12" src="assets/icons/desing.svg"/>
              <img *ngIf="categoria == 5" class="circle col-12" src="assets/icons/gastronomia.svg"/>
              <img *ngIf="categoria == 6" class="circle col-12" src="assets/icons/artesania.svg"/>
              <img *ngIf="categoria == 7" class="circle col-12" src="assets/icons/arte_digital.svg"/>
            </div>
          </div>


          <div class="row col-8" style="padding-top: 20px;">

            <div class="col-lg-2 col-md-2" *ngIf="usuario.instagram">
             <a href="{{usuario.instagram}}" target="_blank">
              <img class="circle col-12" src="assets/icons/insta.svg"/>
             </a>
            </div>
            <div class="col-lg-2 col-md-2" *ngIf="usuario.twitter">
              <a href="{{usuario.twitter}}" target="_blank">
                <img class="circle col-12" src="assets/icons/twitter.svg"/>
              </a>
            </div>
            <div class="col-lg-2 col-md-2" *ngIf="usuario.facebook">
              <a href="{{usuario.facebook}}" target="_blank">
                <img class="circle col-12" src="assets/icons/facebook.svg"/>
              </a>
            </div>

          </div>

        </div>

        <div class="col-lg-5 d-flex justify-content-end col-md-1">
          <button *ngIf="paginaPropia" (click)="openDialog()" mat-raised-button class="round-button" color="accent" aria-label="Agregar artículo">
            <mat-icon>add</mat-icon>
          </button>
        </div>

      </div>

    </div>

    <div style="padding-bottom: 50px;">
      <p class="h5">Difunde las campañas de {{usuario.nombre}} {{usuario.apellido}}</p>
    </div>

   <div class="col-12 d-flex justify-content-center"  *ngIf="selectedImgEvento">
    <div class="col-1 d-flex justify-content-center">
      <button mat-fab (click)="cambiarImgByIndex(eventIndex-1)" class="btn-change" style="margin: auto;">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="arrow_back_ios" class="margin-auto"></mat-icon>
      </button>
    </div>
    <div class="col-10" style="padding-top: 60px;">

      <div class="col-12 row" style="padding-bottom: 30px;" >
        <div class="col-8">
          <p class="h4" style="text-align: left !important;">Difunde las historias de {{usuario.nombre}} {{usuario.apellido}}</p>
        </div>

        <div class="col-4 d-flex justify-content-end">
          <button *ngIf="paginaPropia"  class="btn institutional-button col-5 margin-auto" [matMenuTriggerFor]="menu">Opciones</button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="update()" >Editar</button>
            <button mat-menu-item (click)="delete()">Eliminar artículo</button>
          </mat-menu>

          <button *ngIf="paginaPropia"  class="btn institutional-button col-5 margin-auto" (click)="enviarEmail(selectedImgEvento)">Mover a destacados</button>
        </div>
      </div>

      <div class="imagen" *ngIf="selectedImgEvento" >

        <img  src="{{selectedImgEvento}}" alt="" >

      </div>
      <div class="row" style="padding-top: 20px;">
        <div class="col-6">
          <p class="h4">
            {{selectedTituloEvento}}
          </p>
        </div>
        <div class="col-6 row justify-content-end d-flex"  >
          <div class="col-lg-2 col-md-2 ">
            <p class="h6">
              Compartir
            </p>
          </div>
           <div class="col-lg-1 col-md-2 ">
            <a  href="https://twitter.com/intent/tweet?text=Checa los eventos de {{usuario.nombre}} {{usuario.apellido}} y otros artistas en Creatopolis &url=http://proyectoshm.com/creatopolis/difundir?id={{usuario.email}}" target="_blank">
               <img class="circle " src="assets/icons/twitter.svg"/>
             </a>
           </div>

           <div class="col-lg-1 col-md-2 ">
             <a  href="https://www.facebook.com/sharer/sharer.php?u=http://proyectoshm.com/creatopolis/difundir?id={{usuario.email}}" target="_blank">
               <img class="circle "  src="assets/icons/facebook.svg"/>
             </a>
           </div>
        </div>

      </div>

    </div>

    <div class="col-1 d-flex justify-content-center">
      <button mat-fab (click)="cambiarImgByIndex(eventIndex+1)" class="btn-change" style="margin: auto;">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="arrow_forward_ios" class="margin-auto"></mat-icon>
      </button>
    </div>
   </div>

   <div class="col-12 d-flex justify-content-center" style="padding-top: 60px; padding-bottom: 40px;" *ngIf="selectedImgEvento">

    <div class="col-1 d-flex justify-content-center">
      <button mat-fab (click)="cambiarArrayIndex(arrayIndex-1)" class="btn-change" style="margin: auto;">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="arrow_back_ios" class="margin-auto"></mat-icon>
      </button>
    </div>

    <div class="row col-10">
      <div class="col-md-10 col-lg-1 " *ngFor="let item of eventmatrix[arrayIndex]" >
        <app-evento [dataDialog]="item" (selectedEvent)="selectEvent($event); setIndex();" [ngClass]="{'opacity-half': selectedEvent.id != item.id }"></app-evento>
      </div>
    </div>

    <div class="col-1 d-flex justify-content-center" style="height: 100px;">
      <button mat-fab (click)="cambiarArrayIndex(arrayIndex+1)" class="btn-change" style="margin: auto;">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="arrow_forward_ios" class="margin-auto"></mat-icon>
      </button>
    </div>
   </div>

   <div  *ngIf="selectedImgEvento === ''" class="text-center margin-auto">
    <p class="h5">Aún no se ha cargado campañas para difundir</p>
    </div>
    <div style="height: 120px;">

    </div>
  </div>

</div>
