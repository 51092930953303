import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { Direccion } from 'src/app/models/direccion';
import { Usuario } from 'src/app/models/usuario';
import { QueryServiceService } from 'src/app/services/query-service.service';
import { Categoria } from '../utils/reduce/categorias/categoria';
import { Store } from '@ngrx/store';
import { selectCaregorias } from '../utils/reduce/categorias/categoria.selectors';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit {

  title = 'angular-google-maps-app';

  @ViewChild('myGoogleMap', { static: false })
  map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false })
  info!: MapInfoWindow;

  @Input()
  width = "";

  @Input()
  height = "";

  @Input()
  mode = "";

  @Input()
  nombre = "";

  lat = 0;
  lng = 0;

  categorias$: Observable<Categoria>;

  zoom = 12;
  maxZoom = 15;
  minZoom = 8;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    disableDoubleClickZoom: true,
    mapTypeId: 'hybrid',
  }

  markers = [] as any ;
  infoContent = sessionStorage.getItem('descripcion');
  usuario: Usuario = {
    nombre: '',
    apellido: '',
    email: '',
    descripcion: '',
    rol: [],
    categorias: [],
    facebook: '',
    instagram: '',
    twitter: ''
  };

  usuarios: Usuario[] = [];

  direcciones: Direccion[] = [];

  constructor(private queryServices: QueryServiceService, private store: Store) {
    this.categorias$ = this.store.select(selectCaregorias);
  }

  ngOnInit() {
    this.categorias$.subscribe((res) => {
    })

    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    });

    if (this.mode === 'EDIT') {
      this.queryServices.getDireccionByUser(sessionStorage.getItem('email') as string).subscribe((response) => {
        this.queryServices.getUsuario(sessionStorage.getItem('email') as string).subscribe((us) => {

          const direction = response.data() as Direccion;
          us.forEach((row) => {
            this.usuario = row.data() as Usuario;
          });
          this.markers = [
            {
              position: {
                lat: direction.lat,
                lng: direction.lng,
              },
              label: {
                color: 'white',
                text: this.usuario.nombre + ' ' + this.usuario.apellido,
              },
              title: 'Marker title ',
              info: this.usuario.nombre + ' ' + this.usuario.apellido,
              icon: 'assets/icons/user.svg',
              options: {
                animation: google.maps.Animation.DROP,
              }
             }
          ];
          this.usuarios.push(this.usuario);
        });
      });
    } else {
      this.buscarCreativos();
      this.direcciones.forEach((dir) => {
        this.markers.push(
          {
            position: {
              lat: dir.lat,
              lng: dir.lng
            },
            label: {
              color: 'white',
              text: dir.nombre,
            },
            title: 'Marker title ',
            info: dir.usuario,
            icon: 'assets/icons/user.svg',
            options: {
              animation: google.maps.Animation.DROP,
            }
           }
        );
      });
    }


  }

  eventHandler(event: any ,name:string){

    // Add marker on double click event
    if(name === 'mapDblclick' && this.mode === 'EDIT'){
      this.dropMarker(event)
    }
  }

  // Markers
  logCenter() {
    console.log(JSON.stringify(this.map.getCenter()))
  }

  dropMarker(event:any) {
    this.markers = [
      {
        position: {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        },
        label: {
          color: 'white',
          text: this.usuario.nombre + ' ' + this.usuario.apellido,
        },
        title: 'Marker title ',
        info: this.usuario.nombre + ' ' + this.usuario.apellido,
        icon: 'assets/icons/user.svg',
        options: {
          animation: google.maps.Animation.DROP,
        }
       }
    ];
    this.lat = event.latLng.lat();
    this.lng = event.latLng.lng();
  }

  openInfo(marker: MapMarker, content: string) {
    if (this.mode !== 'EDIT')
    this.queryServices.getUsuario(content).subscribe((us) => {
      us.forEach((row) => {
        this.usuario = row.data() as Usuario;
      });
      this.infoContent = content;
      this.info.open(marker)
    });
  }

  guardarDireccion(): void {
    this.queryServices.guardarDireccion(sessionStorage.getItem('email') as string, {
      lat: this.lat,
          lng: this.lng,
          domicilio: '',
          usuario: sessionStorage.getItem('email') as string,
          nombre: this.nombre
    }).subscribe((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Se ha actualizado su información',
        text: '',
        confirmButtonText: 'Continuar'
      });
    });
  }

  buscarCreativos(): void {

    this.categorias$.subscribe((cats) =>  {
      if (cats.categorias.length > 0) {
        this.queryServices.getAllCreativosByCategoria(cats.categorias).subscribe((response) => {
          this.usuarios = [];

          response.forEach((row) => {
            this.usuarios.push(row.data() as Usuario);
          });
          this.filtrarCreativosPorNombre(cats.nombre);

          this.getDirections(this.usuarios.map((row) => row.email));
        });

       } else {
        this.queryServices.getAllCreativos().subscribe((response) => {
          this.usuarios = [];
          response.forEach((row) => {
            this.usuarios.push(row.data() as Usuario);
          });
          this.getDirections(this.usuarios.map((row) => row.email));
        });
       }

    });
  }

  getDirections(emails: string[]): void {
    if (emails.length != 0) {
      this.queryServices.getDirecciones(emails).subscribe((res) => {
        this.direcciones = [];
        this.markers = [];
        res.forEach((dir) => {
          const direction = dir.data() as Direccion;

          this.markers.push(
            {
              position: {
                lat: direction.lat,
                lng: direction.lng
              },
              label: {
                color: 'white',
                text: direction.nombre,
              },
              title: 'Marker title ',
              info: direction.usuario,
              icon: 'assets/icons/user.svg',
              options: {
                animation: google.maps.Animation.DROP,
              }
             }
          );
        });
        });
    } else {
      this.markers = [];
    }

  }

  filtrarCreativosPorNombre(nombre: string) {
    this.usuarios = this.usuarios.filter((us) => (us.nombre.toUpperCase().includes(nombre.toUpperCase()) || us.apellido.toUpperCase().includes(nombre.toUpperCase())));
  }

}
