<app-header></app-header>
<div class="div-center">
  <div class="main-container">
    <router-outlet>
    </router-outlet>
    <div style="height: 100px;">
    </div>
  </div>
</div>


<footer class="bg-light text-center text-lg-start">
  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: rgba(138, 138, 138, 0.2);">
    {{footerText}}
    <a class="text-dark" href="/aviso">Aviso de privacidad</a>
  </div>
  <!-- Copyright -->
</footer>
