import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/usuario';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { QueryServiceService } from 'src/app/services/query-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Evento } from 'src/app/models/evento';
import { AgregarEventoComponent, EventoDialog } from './agregar-evento/agregar-evento.component';
import { EventosService } from 'src/app/services/eventos.service';
import { DeleteComponent } from '../dialogs/delete/delete.component';
import { Location } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-difundir',
  templateUrl: './difundir.component.html',
  styleUrls: ['./difundir.component.css'],
})
export class DifundirComponent implements OnInit {

  usuario: Usuario = {
    nombre: '',
    apellido: '',
    email: '',
    descripcion: '',
    rol: [],
    categorias: [],
    facebook: '',
    instagram: '',
    twitter: ''
  }

  eventmatrix: any[] = [];
  eventosList:EventoDialog[]  = [];
  userId = '';
  userImgURL:string = '';
  portadaImgUrl: string = '';
  paginaPropia = false;
  selectedImgEvento = '';
  selectedTituloEvento = '';
  arrayIndex = 0;
  eventIndex = 0;
  urlImagenSeleccionada = '';

  selectedEvent: EventoDialog = {
    evento: {
      titulo: '',
      fechaCreacion: new Date(),
      urlImagen: '',
      autor: ''
    },
    imgUrl: '',
    id: ''
  }

  constructor(private emailServices: EmailService,private meta:Meta,private _location: Location, private eventoServices: EventosService, private store: Store, private imagenServices: ImagenesService, private _Activatedroute: ActivatedRoute, private queryServices: QueryServiceService,  private router: Router, private dialog: MatDialog) {
  
   }

  ngOnInit(): void {
    this._Activatedroute.queryParams.subscribe((params) => {
      this.userId = atob(params['id']);
      this.paginaPropia = this.userId === sessionStorage.getItem('email') as string;
      this.getEventos(this.userId);
      this.getUsuarioData(this.userId);
      this.getImagen(this.userId, 'portada');
      this.getImagen(this.userId, 'perfil');
    });
  }

  backClicked() {
    this._location.back();
  }

  selectEvent(event: EventoDialog): void  {
    this.selectedEvent = event;
    if (this.selectedEvent.imgUrl) {
      this.seleccionarBanner(this.selectedEvent.imgUrl);
    } else {
      this.imagenServices.obtenerImagenByPath(this.selectedEvent.evento.urlImagen).subscribe((response) => {
        this.selectedEvent.imgUrl = response
        this.seleccionarBanner(this.selectedEvent.imgUrl);
      });
    }
    this.seleccionarTituloBanner(this.selectedEvent.evento.titulo);
  }

  setIndex(): void {
    this.eventIndex =this.eventmatrix[this.arrayIndex].indexOf(this.selectedEvent);
  }

  cambiarImgByIndex(newIndex: number): void {
    if (newIndex < 0) {
      this.cambiarArrayIndex(this.arrayIndex-1);
    } else {
      this.eventIndex = newIndex > this.eventmatrix[this.arrayIndex].length-1 ? 0 : newIndex;
      if(this.eventIndex == 0) {
        this.cambiarArrayIndex(this.arrayIndex+1);
      } else {
      this.selectEvent(this.eventmatrix[this.arrayIndex][this.eventIndex]);
      }
    }
  }

  cambiarArrayIndex(newIndex: number): void {

    if (newIndex < 0) {
      this.arrayIndex = (this.eventmatrix.length-1);
      this.eventIndex = this.eventmatrix[this.arrayIndex].length-1;
    } else {
      const auxIndex = this.arrayIndex;
      this.arrayIndex = newIndex > this.eventmatrix.length-1 ? 0 : newIndex;

      this.eventIndex = newIndex > auxIndex ? 0 : this.eventmatrix[this.arrayIndex].length-1;
    }
    this.selectEvent(this.eventmatrix[this.arrayIndex][this.eventIndex]);
  }


  cambiarImagen(newIndex: number): void {
    if (newIndex < 0) {
      this.arrayIndex = (this.eventmatrix.length-1);
    } else {
      this.arrayIndex = newIndex > this.eventmatrix.length-1 ? 0 : newIndex;
    }
  }

  getImagen(userId: string, coleccion: string): void {
    this.imagenServices.obtenerImagen(userId, coleccion).subscribe((response) => {
      switch (coleccion) {
        case 'portada':
          this.portadaImgUrl = response;
          break;
        case 'perfil':
          this.userImgURL = response;
        break;
        default:
          break;
      }
    });
  }

  getUsuarioData(userId: string): void {
    this.queryServices.getUsuario(userId).subscribe((resp) => {
      resp.forEach((row) => {
        this.usuario = row.data() as Usuario;
      });
    });
  }




  navegar(ruta: string, idUser: string):void{
    if (idUser) {
      this.router.navigate([ruta],
        { queryParams: { id: idUser } }
      );
    } else {
      this.router.navigate([ruta]);
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open<AgregarEventoComponent, Evento>(AgregarEventoComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveEvento(result.dataDialog, result.imagen);
      }
    });
  }

  construirLista(lista: any[]) {
    if (lista.length == 0) {
      return;
    } else {
      this.eventmatrix.push(lista.slice(0,12));
      this.construirLista(lista.slice(12));
    }
  }

  getEventos(userId: string): void {
      this.eventoServices.getEventos(userId).subscribe((res) => {
        this.eventosList = [];
        res.forEach((row) => {
          this.eventosList.push({
             evento: row.data() as Evento,
             id: row.id,
             imgUrl:''
          }
          );
        });
        this.eventmatrix = [];
        this.construirLista(this.eventosList);
        this.selectedEvent.evento = this.eventosList[0].evento;
        this.selectedEvent.id = this.eventosList[0].id;
        this.imagenServices.obtenerImagenByPath(this.eventosList[0].evento.urlImagen).subscribe((response) => {
          this.selectedEvent.imgUrl = response
          this.selectEvent(this.selectedEvent);
        });
      });
  }

  updateEvento(data: {dataDialog: EventoDialog, imagen: File}) {
    if (data.imagen != undefined) {
      this.imagenServices.subirImagen(data.imagen, data.dataDialog.evento.titulo.replaceAll(' ', '').toLocaleUpperCase(), `evento/${data.dataDialog.evento.autor}`).subscribe((res) => {
        data.dataDialog.evento.urlImagen = res.metadata.fullPath;
        this.eventoServices.actualizarEvento(data.dataDialog.evento , data.dataDialog.id).subscribe((response) => {
          this.getEventos(this.userId);
        });
      });
    } else {
      this.eventoServices.actualizarEvento(data.dataDialog.evento , data.dataDialog.id).subscribe((response) => {
        this.getEventos(this.userId);
      });
    }
  }

  deleteEvento(dataDialog: EventoDialog): void {
    this.eventoServices.eliminarEvento(dataDialog.id).subscribe((res) => {
      this.imagenServices.deleteImagen(dataDialog.evento.urlImagen).subscribe();
      this.eventosList = [];
      this.getEventos(this.userId);
    })
  }

  saveEvento(data: EventoDialog, imagen:File):void{
    const evento: Evento = data.evento;
    this.imagenServices.subirImagen(imagen,evento.titulo.replaceAll('','').toLocaleUpperCase(), `evento/${evento.autor}`).subscribe((response)=>{
      evento.urlImagen = response.metadata.fullPath;
      this.eventoServices.guardarEvento(evento, evento.autor).subscribe((response)=>{
        this.getEventos(this.userId);
      });
    });
  }

  seleccionarBanner(imagen: string ):void{
    this.selectedImgEvento = imagen;
  }

  seleccionarTituloBanner(titulo: string){
    this.selectedTituloEvento = titulo;
  }


  update():void{
    let config: MatDialogConfig = new MatDialogConfig();
    config.data = this.selectedEvent;
    const dialogRef = this.dialog.open<AgregarEventoComponent, MatDialog>(AgregarEventoComponent, config);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateEvento(result)
      }
    });
  }

  delete():void{
    let config: MatDialogConfig = new MatDialogConfig();
    config.data = "Eliminar Evento";
    const dialogRef = this.dialog.open<DeleteComponent, string>(DeleteComponent, config);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteEvento(this.selectedEvent);
      }
    });
  }

  enviarEmail(imagen: string):void{
    const body = "<p>Esta es la propuesta del usuario "+this.userId+"</p>"+
                 "<p>"+imagen+"</p>"; 
    this.emailServices.enviarEmail("Propuesta para banner destacado", "pruebacreatopolis@gmail.com", body).subscribe((result)=>{
      console.log(result);
    });
  }

}
