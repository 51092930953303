import { Injectable } from '@angular/core';
import { Firestore, addDoc, collection } from '@angular/fire/firestore';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private firestore: Firestore) { }


public enviarEmail(subject: string, email: string, body:string){
  const referencia = collection(this.firestore, 'mail'); 
  const emailContent = {
    to: email,
    message: {
        subject: subject,
        html: body
    }
  };
  return from (addDoc(referencia, emailContent));
}



}
