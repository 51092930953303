import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Evento } from 'src/app/models/evento';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { QueryServiceService } from 'src/app/services/query-service.service';
import { AgregarEventoComponent, EventoDialog } from '../agregar-evento/agregar-evento.component';
import { EventosService } from 'src/app/services/eventos.service';
import { DeleteComponent } from '../../dialogs/delete/delete.component';

@Component({
  selector: 'app-evento',
  templateUrl: './evento.component.html',
  styleUrls: ['./evento.component.css']
})
export class EventoComponent implements OnInit {

  @Input()
  dataDialog: EventoDialog = {
    evento: {
      titulo: '',
      urlImagen: '',
      autor: '',
      fechaCreacion: new Date(),
    },
    id: '',
    imgUrl: ''
  };

  imgUrl = '';
  paginaPropia = false;
  tituloImg = '';

  @Output()
  selectedtUrlEvent = new EventEmitter<string>();

  @Output()
  selectedEvent = new EventEmitter<EventoDialog>();

  @Output()
  selectedTituloEvent = new EventEmitter<string>();

  @Output()
  newEventEvent = new EventEmitter<{dataDialog: EventoDialog, file: File}>();

  @Output()
  deleteEvent = new EventEmitter<EventoDialog>();

  constructor(private eventoServices: EventosService, private router: Router, private dialog: MatDialog, private imagenServices: ImagenesService, private queryServices: QueryServiceService) { }

  ngOnInit(): void {
    this.paginaPropia = this.dataDialog.evento.autor === sessionStorage.getItem('email') as string;
    this.eventoServices.getTituloEvento(this.dataDialog.evento.titulo).subscribe((res)=>{
      this.tituloImg = res;
    });
    this.imagenServices.obtenerImagenByPath(this.dataDialog.evento.urlImagen).subscribe((response) => {
      this.dataDialog.imgUrl = response;
      this.imgUrl = response;
    });
  }

  navigate(url: string): void {
    this.router.navigate([url]);
  }

  emitEvent(dataDialog: EventoDialog, file: File) {
    this.newEventEvent.emit({dataDialog: dataDialog, file: file});
  }


  selectImagen():void{
    this.selectedtUrlEvent.emit(this.imgUrl);
  }

  selectTitulo():void{
    this.selectedTituloEvent.emit(this.tituloImg);
  }

  selectEvent():void {
    this.selectedEvent.emit(this.dataDialog);
  }

  update():void{
    let config: MatDialogConfig = new MatDialogConfig();
    this.dataDialog.imgUrl = this.imgUrl;
    config.data = this.dataDialog;
    const dialogRef = this.dialog.open<AgregarEventoComponent, MatDialog>(AgregarEventoComponent, config);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
        this.dataDialog = result.dataDialog;
        console.log(this.dataDialog)
        this.emitEvent(this.dataDialog, result.imagen);
      }
    });
  }

  delete():void{
    let config: MatDialogConfig = new MatDialogConfig();
    config.data = "Eliminar Evento";
    const dialogRef = this.dialog.open<DeleteComponent, string>(DeleteComponent, config);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteEvent.emit(this.dataDialog);
      }
    });
  }
}
