<div class="row full">
  <div class="col-12 d-flex justify-content-center">
    <h1>Nuevo Evento</h1>
  </div>

  <div class="col-12 row">

    <div class="col-9">
      <form [formGroup]="form">

        <div class="row col-12">
          <mat-form-field class="col-6">
            <mat-label>Titulo</mat-label>
            <input matInput placeholder="Titulo del evento" value="" formControlName="titulo">
          </mat-form-field>

          <mat-form-field class="col-6">
            <mat-label>Agrega el rango de fechas</mat-label>
              <mat-date-range-input [rangePicker]="picker"  [min]="startDate">
                <input matStartDate placeholder="Incio" formControlName="fechasEventosInicio">
                <input matEndDate placeholder="Fin" formControlName="fechasEventosFinal">
              </mat-date-range-input>
              <mat-hint>Mes/Día/Año</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker [startAt]="startDate"></mat-date-range-picker>
          </mat-form-field>

        </div>

        <div class="row">
          <div class="col-8">
            <mat-label>Horarios:</mat-label>
            <div class="col-12 row">
              <div class="col-4">
                <mat-form-field >
                  <mat-label>Hora:</mat-label>
                  <mat-select id="hora"  placeholder="Hora del evento" formControlName="horariosH">
                    <mat-option value="1">1</mat-option>
                    <mat-option value="2">2</mat-option>
                    <mat-option value="3">3</mat-option>
                    <mat-option value="4">4</mat-option>
                    <mat-option value="5">5</mat-option>
                    <mat-option value="6">6</mat-option>
                    <mat-option value="7">7</mat-option>
                    <mat-option value="8">8</mat-option>
                    <mat-option value="9">9</mat-option>
                    <mat-option value="10">10</mat-option>
                    <mat-option value="11">11</mat-option>
                    <mat-option value="12">12</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-4">
                <mat-form-field >
                  <mat-label>Minutos:</mat-label>
                  <mat-select id="min" placeholder="" value="" formControlName="horariosM">
                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">{{ opcion }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-4">
                <mat-form-field >
                  <mat-label>PM/AM:</mat-label>
                  <mat-select id="sHorario" placeholder="" value="" formControlName="horariosT">
                    <mat-option value="a.m.">am</mat-option>
                    <mat-option value="p.m.">pm</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

          </div>

          <div class="col-4 margin-auto">
            <button mat-raised-button color="accent" aria-label="Agregar Horario" [disabled]="form.controls['horariosH'].value === '' || form.controls['horariosM'].value === '' || form.controls['horariosT'].value === ''" (click)="addHorario()">Agregar</button>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <mat-form-field class="col-6">
            <mat-label>Boletera</mat-label>
            <input type="url" matInput placeholder="www.boletos.com" value="" formControlName="boletera">
          </mat-form-field>
        </div>

      </form>
    </div>

    <div class="col-3" >
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 col-12">
        <ng-container matColumnDef="Hora">
          <th mat-header-cell *matHeaderCellDef> Hora </th>
          <td mat-cell *matCellDef="let element"> {{element.Hora}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

  </div>

  <div style="padding-top: 20px;">

    <div class="col-12">
      <div *ngIf="image"   class="imagen">
        <img class=" img" [src]="image" alt="Imagen de perfil" />
      </div>
    </div>

    <div *ngIf="image ===''" (click)="uploadImg.click()" class="col-12 d-flex justify-content-center martin-top-20 margin-bottom-20 media-container margin-auto">
      <div class="margin-auto">
         <div class="col-12  d-flex justify-content-center" >
           <input hidden type="file" #uploadImg accept=".png, .jpg , .jpeg"  (change)="subirImagen($event)">
           <button mat-button color="primary" aria-label="Agregar foto">Cargar Imagen</button>
         </div>

      </div>
    </div>

  </div>

  <div class="col-12  d-flex justify-content-center" style="height: 36px; padding-top: 10px;">
    <input hidden type="file" #uploadImg accept=".png, .jpg , .jpeg"  (change)="subirImagen($event)">
    <button  *ngIf="image !==''" mat-button color="primary" aria-label="Agregar foto" (click)="uploadImg.click()">Cargar Imagen</button>
  </div>

  <div class="col-12 d-flex justify-content-center margin-05-rem">
    <div class="margin-1-rem">
      <button mat-button color="warn" (click)="onNoClick(false)">Cancelar</button>
    </div>

    <div class="margin-1-rem">
      <button mat-raised-button color="accent" [disabled]="form.invalid || image === '' || events.length == 0" (click)="onNoClick(true)">Publicar</button>
    </div>
  </div>

</div>
