import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-open',
  templateUrl: './open.component.html',
  styleUrls: ['./open.component.css']
})
export class OpenComponent implements OnInit {

  isVideo = false;

  constructor(public dialogRef: MatDialogRef<OpenComponent>, @Inject(MAT_DIALOG_DATA) public data: {url: string, paginaPropia: boolean}) { }

  ngOnInit(): void {
    this.isVideo = this.data.url.includes('.video');
  }


  onNoClick(value: boolean): void {
    this.dialogRef.close(value);
  }

}
