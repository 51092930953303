import { Injectable } from '@angular/core';
import { Firestore, QuerySnapshot, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from '@angular/fire/firestore';
import { Show } from '../models/show';
import { from, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ShowsService {

  constructor(private firestore: Firestore, private http: HttpClient) { }

  public guardarShow(show: Show, email: string){
    const referencia = doc(collection(this.firestore,`shows/`));
    return from (setDoc(referencia, show));
  }

  public getShows(email: string){
    return from (getDocs(query(collection(this.firestore,"shows"),where("autor", "==",email))));
  }

  public eliminarShow(id: string){
    const referencia = doc(this.firestore,`shows/${id}`);
    return from (deleteDoc(referencia));
  }

}
