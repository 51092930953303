
  <div class="width-600">



    <div class="col-12 row">
      <div class="col-12  d-flex justify-content-center  ">
        <p class="h5">
          Nueva publicación
        </p>
      </div>

      <div *ngIf="format !==''" class="col-12 d-flex justify-content-center martin-top-20 margin-bottom-20 media-container margin-auto">
       <div class="margin-auto">
          <img [src]="url" *ngIf="format==='image' && url" class="full-content">
          <video [src]="url" *ngIf="format==='video' && url" class="full-content" controls></video>
       </div>
      </div>

      <div *ngIf="format ===''"  class="col-12 d-flex justify-content-center martin-top-20 margin-bottom-20 media-container margin-auto">

        <input hidden type="file" #uploadImg accept=".png, .jpg , .jpeg, .mp4"  (change)="subirImagen($event)">
        <button mat-button c aria-label="Agregar foto" (click)="uploadImg.click()" style="height: 100%; width: 100%; background: rgba(193, 193, 193, 0.321);">Cargar</button>
      </div>

      <div class="col-12  d-flex justify-content-center" style="height: 36px;">
        <input hidden type="file" #uploadImg accept=".png, .jpg , .jpeg, .mp4"  (change)="subirImagen($event)">
        <button  *ngIf="format !==''" mat-button color="primary" aria-label="Agregar foto" (click)="uploadImg.click()">Cargar</button>
      </div>
    </div>

    <div class=" row col-12 martin-top-20">
      <div class="col-6  d-flex justify-content-end">
        <button mat-button color="warn" (click)="onNoClick(false)" [disabled]="waithing && url != null">Cancelar</button>
      </div>

      <div class="col-6  d-flex justify-content-start">
        <button mat-raised-button color="accent" [disabled]="waithing" (click)="onNoClick(true)">Guardar</button>
      </div>
    </div>

  </div>



