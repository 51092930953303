import { Component, OnInit } from '@angular/core';
import { QueryServiceService } from './services/query-service.service';
import { Store } from '@ngrx/store';
import { UserActions } from './components/utils/reduce/auth.actions';
import { Usuario } from './models/usuario';
import { ImagenesService } from './services/imagenes.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  footerText = '© Creatopolis 2024.';
  title = 'creatopolis';

  constructor(private queryServices: QueryServiceService, private store: Store, private imagenServices: ImagenesService){}

  ngOnInit(): void {
    if(sessionStorage.getItem('email') as string){
      this.queryServices.getUsuario(sessionStorage.getItem('email') as string).subscribe((res) => {
        res.forEach((row) => {
          this.store.dispatch(UserActions.saveUsuario({usuario: row.data() as Usuario}));
        });
        // this.obtenerImagenPerfil(sessionStorage.getItem('email') as string);
      });
    }

  }


  obtenerImagenPerfil(email: string):void{

    this.imagenServices.obtenerImagen(email, 'perfil').subscribe((response)=>{
      sessionStorage.setItem('perfilURL', response);



    });

    this.imagenServices.obtenerImagen(email, 'portada').subscribe((res)=>{

      console.log(res)
      sessionStorage.setItem('portadaUrl', res);

    });


  }

}
