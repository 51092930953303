<div class="d-flex justify-content-center text-center" style="margin-top: 150px;">

  <div class="width-25-rem">

    <div class="margin-2-rem div-center">
      <div class="circle">
        <img class="xl-icon" src="assets/icons/logo.svg" />
      </div>
    </div>
    <div class="margin-1-rem">
      <p class="h5"*ngIf="!restablecer">Iniciar sesión</p>
      <p class="h5"*ngIf="restablecer">Recuperar Contraseña</p>

    </div>
    <div>
      <form [formGroup]="form">
        <div class="margin-1-rem">
          <input type="email" class="form-control" name="" id="" placeholder="Correo electrónico" formControlName="email">
        </div>
        <div class="margin-1-rem text-rigth" >
          <input type="password" *ngIf="!restablecer" class="form-control" name="" id="" placeholder="Contraseña" formControlName="password" style="margin-bottom: 5px;">
          <p class="cursos-pointer" (click)="setRestablecer(true)"*ngIf="!restablecer">Restaurar contraseña</p>
          <p class="cursos-pointer" (click)="setRestablecer(false)"*ngIf="restablecer">Iniciar sesion</p>
        </div>

      </form>

    </div>

      <div class="margin-1-rem" >
        <button class="btn institutional-button" (click)="login()" [disabled]="this.form.controls['email'].value === '' || this.form.controls['password'].value === ''" *ngIf="!restablecer" >Entrar</button>
        <button class="btn institutional-button" (click)="restablecerPassword()" [disabled]="this.form.controls['email'].value === ''" *ngIf="restablecer" >Restablecer</button>

      </div>

      <div class="margin-1-rem">
      ¿No tienes cuenta? <a routerLink="/registro">Regístrate aquí</a>
      </div>
  </div>

</div>


