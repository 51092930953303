import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { Usuario } from '../models/usuario';
import { Firestore, collection, doc, documentId, getDoc, getDocs, query, setDoc, where, deleteDoc } from '@angular/fire/firestore';
import { Direccion } from '../models/direccion';

@Injectable({
  providedIn: 'root'
})
export class QueryServiceService {

  constructor( private firestore: Firestore) { }

  public getAllCreativos() {
    return from (
      getDocs(
        query(collection(this.firestore, "usuarios"), where("rol", "array-contains-any", ['1']))
      )
    );
  }

  public getAllCreativosByCategoria(categorias: number[]) {
    return from (
      getDocs(
        query(collection(this.firestore, "usuarios"), where("categorias", "array-contains-any", categorias))
      )
    );
  }

  public getUsuario(email: string){
    return from (getDocs(
      query(collection(this.firestore, "usuarios"), where("email", "==", email))
    ));
  }


  public guardarDireccion(email: string, direccion: Direccion){
    const referencia = doc(collection(this.firestore,'mapa'), email);
    return from (setDoc(referencia,direccion));
  }

  public getDireccionByUser(email: string){
    return from (getDoc(
      doc(this.firestore, "mapa", email)
    ));
  }


  public getDirecciones(emails: string[]){
    return from (
      getDocs(
        query(collection(this.firestore, "mapa"), where(documentId(), "in", emails))
      )
    );
  }

}
