<div class="row col-12" style="height: 550px;">

  <div class="col-3 calendar-card">
    <mat-card >
      <mat-calendar [dateClass]="dateClass"></mat-calendar>
    </mat-card>
  </div>
  <div class="col-9 imagen">
    <img src={{show.imgUrl}} class="">

    <div class="margin-1-rem row col-12">
      <div class="col-6">
        <p class="h2">{{show.show.titulo}}</p>
        <p class="h6">
          {{show.show.horarios}}
        </p>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <div class="margin-1-rem" *ngIf="!paginaPropia">

          <a href="{{show.show.boleteraUrl}}" target="_blank" hidden #redirect></a>
          <button mat-raised-button color="accent" (click)="redirect.click()">Comprar boleto</button>
        </div>

        <div class="margin-1-rem" *ngIf="paginaPropia">

          <a href="{{show.show.boleteraUrl}}" hidden #redirect></a>
          <button mat-raised-button color="accent" (click)="delete()">Eliminar Evento</button>
        </div>
      </div>
    </div>


  </div>


</div>
