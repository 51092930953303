import { Injectable } from '@angular/core';
import { Firestore, collection, deleteDoc, doc, getDocs, query, setDoc, where } from '@angular/fire/firestore';
import { from } from 'rxjs';
import { Articulo } from '../models/articulo';

@Injectable({
  providedIn: 'root'
})
export class ArticulosServices {

  constructor(private firestore: Firestore) { }


  public guardarArticulo(articulo: Articulo, email: string){
    const referencia = doc(collection(this.firestore,`articulos`));
    return from (setDoc(referencia, articulo));
  }

  public eliminarArticulo(id: string){
    const referencia = doc(this.firestore,`articulos/${id}`);
    return from (deleteDoc(referencia));
  }

  public getArticulos(email: string){
    return from (
      getDocs(
        query(collection(this.firestore, "articulos"), where("autor", "==", email))
      )
    );
  }

  public actualizarArticulo(articulo: Articulo, id: string){
    const referencia = doc(collection(this.firestore,'articulos'), id);
    return from (setDoc(referencia,articulo));
  }
}
