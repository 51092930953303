<div class="resume-card col-12 background-color-white-1 shadow-box-1">

  <div class="image2 text-center " >

    <img *ngIf="imgUrl" [src]="imgUrl" />

  </div>

  <div class="row margin-1-rem">
    <div class="col-9">
      <p class="h6">
        {{dataDialog.articulo.nombre.substring(0, 40) + (dataDialog.articulo.nombre.length >= 40 ? "..." : "") }}
       </p>
    </div>
    <div class="col-3 d-flex justify-content-end">
      <p class="h6">
       ${{dataDialog.articulo.precio}}
      </p>
    </div>
  </div>



</div>

<div class="row d-flex justify-content-around">

  <div class=" col-4" *ngIf="!paginaPropia" >
    <a href="{{dataDialog.articulo.link}}" target="_blank" hidden #redirect></a>

    <button *ngIf="dataDialog.articulo.link" class="btn institutional-button margin-1-rem  col-12" (click)="redirect.click()">Comprar</button>
  </div>

  <div class="col-4" *ngIf="paginaPropia">
    <button class="btn institutional-button margin-1-rem col-12" [matMenuTriggerFor]="menu">Opciones</button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="update()">Editar</button>
      <button mat-menu-item (click)="delete()">Eliminar artículo</button>
    </mat-menu>

  </div>
</div>
