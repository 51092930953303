import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-insignia',
  templateUrl: './insignia.component.html',
  styleUrls: ['./insignia.component.css']
})
export class InsigniaComponent implements OnInit {

  insigniaTittle = 'Insignia 1'

  constructor(private dialogRef: MatDialogRef<InsigniaComponent>) { }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

}
