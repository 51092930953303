import { createActionGroup, props } from '@ngrx/store';
import { Usuario } from 'src/app/models/usuario';



export const UserActions = createActionGroup({
  source: 'usuario',
  events: {
    'save Usuario': props<{ usuario: Usuario }>(),
    'set Categorias': props<{ usuario: Usuario }>()
  },
})
