import { Injectable } from '@angular/core';
import { Evento } from '../models/evento';
import { Firestore, collection, deleteDoc, doc, getDocs, query, setDoc, where } from '@angular/fire/firestore';
import { from, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventosService {

  constructor( private firestore: Firestore) { }


  
  public guardarEvento(evento: Evento, email: string){
    const referencia = doc(collection(this.firestore,`eventos/`));
    return from (setDoc(referencia, evento));
  }

  public getEventos(email: string){
    return from (getDocs(query(collection(this.firestore,"eventos"),where("autor", "==",email))));
  }

  public getTituloEvento(titulo:string){
    const eventosCollection = collection(this.firestore, 'eventos');
    const consulta = query(eventosCollection, where('titulo','==',titulo));

    return from(getDocs(consulta)).pipe(
      map((querySnapshot)=>{
      if(!querySnapshot.empty){
        const documento = querySnapshot.docs[0];
        const data = documento.data();
        return data['titulo'];
      }else{
        throw new Error('Evento no encontrado');
      }
    })
    );
  }

  public eliminarEvento(id: string){
    const referencia = doc(this.firestore, `eventos/${id}`);
    return from (deleteDoc(referencia));
  }

  public actualizarEvento(evento: Evento, id: string){
    const referencia = doc(collection(this.firestore,'eventos'),id);
    return from (setDoc(referencia,evento));
  }

  

}
