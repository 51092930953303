<div class=" col-12  " (click)="selectImagen(); selectEvent()" (click)="selectTitulo()">

    <div class="image2">

      <img *ngIf="imgUrl" [src]="imgUrl"/>

    </div>

</div>
<!--
<div class="row d-flex justify-content-around">
  <div class="col-4" *ngIf="paginaPropia">
    <button class="btn institutional-button margin-1-rem col-12" [matMenuTriggerFor]="menu">...</button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="update()">Editar</button>
    <button mat-menu-item (click)="delete()">Eliminar artículo</button>
  </mat-menu>
  </div>
</div> -->
