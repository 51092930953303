import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/models/usuario';
import { ImagenesService } from 'src/app/services/imagenes.service';

@Component({
  selector: 'app-resume-card',
  templateUrl: './resume-card.component.html',
  styleUrls: ['./resume-card.component.css']
})
export class ResumeCardComponent implements OnInit, OnChanges {

  @Input()
  usuario!: Usuario;
  userImgURL:string = "";


  constructor(private imagenServices: ImagenesService, private router: Router) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.obtenerImagenPerfil();

  }


  obtenerImagenPerfil():void{

    this.imagenServices.obtenerImagen(this.usuario.email, 'perfil').subscribe((response)=>{
      this.userImgURL = response;
    });
  }

  navegar(ruta: string, idUser: string):void{
    if (idUser) {
      this.router.navigate([ruta],
        { queryParams: { id: idUser } }
      );
    } else {
      this.router.navigate([ruta]);
    }
  }

  toBase64(string: string): string {
    console.log(btoa(string))
    return btoa(string);
  }
}
