import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Articulo } from 'src/app/models/articulo';


export interface DataDialog {
 articulo: Articulo;
 imgUrl: string;
 id: string;
}

@Component({
  selector: 'app-agregar',
  templateUrl: './agregar.component.html',
  styleUrls: ['./agregar.component.css']
})

export class AgregarComponent implements OnInit {
  image: string | SafeUrl = '';
  editado = false;
  form: FormGroup = new FormGroup({});
  file: File | undefined;

  constructor(
    public dialogRef: MatDialogRef<AgregarComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public dataDialog: DataDialog
  ) {}

  ngOnInit(): void {
    if (this.dataDialog) {
      this.image = this.dataDialog.imgUrl;
      this.form = new FormGroup({
        nombre: new FormControl(this.dataDialog.articulo.nombre, [Validators.required]),
        precio: new FormControl(this.dataDialog.articulo.precio, [Validators.required]),
        link: new FormControl(this.dataDialog.articulo.link)
      });
    } else {
      this.dataDialog = {
        articulo: {
          nombre: '',
          precio: 0,
          imagen: '',
          autor: '',
          link: ''
        },
        imgUrl: "",
        id: ""
      }
      this.form = new FormGroup({
        nombre: new FormControl('', [Validators.required]),
        precio: new FormControl('', [Validators.required]),
        link: new FormControl('')
      });
    }
  }


  onNoClick(value: boolean): void {
    if (value) {
      this.dataDialog.articulo.nombre = this.form.controls['nombre'].value;
      this.dataDialog.articulo.precio = this.form.controls['precio'].value;
      this.dataDialog.articulo.autor = sessionStorage.getItem('email') as string;
      this.dataDialog.articulo.link = this.form.controls['link'].value;
      this.dialogRef.close({dataDialog:
        {
          articulo: this.dataDialog.articulo,
          imgUrl: this.dataDialog.imgUrl,
          id: this.dataDialog.id
        }, imagen: this.file});
    } else {
      this.dialogRef.close(value);
    }
  }

  subirImagen($event: any){
    this.file = $event.target.files[0];
    this.image = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL($event.target.files[0])
    );
  }
}
