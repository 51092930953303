<div class="d-flex justify-content-center text-center" style="margin-top: 100px;">



    <div class="width-25-rem">
      <div class="margin-1-rem">
        <p class="h5">Ponte en contacto con nosotros</p>
      </div>



      <form [formGroup]="form">

        <div>
          <div class="margin-1-rem">
            <input type="text" class="form-control" name="" id="" placeholder="Tu nombre" formControlName="nombre">

             <app-mensaje-error [control]="form.get('nombre')!"></app-mensaje-error>

          </div>
          <div class="margin-1-rem">
            <input type="text" class="form-control" name="" id="" placeholder="Tu correo electronico" formControlName="email">
             <app-mensaje-error [control]="form.get('email')!"></app-mensaje-error>

          </div>

          <div class="margin-1-rem">
            <input type="text" class="form-control" name="" id="" placeholder="Tu numero telefonico" formControlName="telefono">
             <app-mensaje-error [control]="form.get('telefono')!"></app-mensaje-error>

          </div>
          <div class="margin-1-rem">
            <input type="text" class="form-control" name="" id="" placeholder="Asunto" formControlName="asunto">
             <app-mensaje-error [control]="form.get('asunto')!"></app-mensaje-error>

          </div>
          <div  class="margin-1-rem div-center  text-center">


          </div>
          <div class="margin-1-rem">
            <textarea type="text" class="form-control" name="" id="" placeholder="Tu mensaje"  formControlName="mensaje" maxlength="150"></textarea>
            <app-mensaje-error [control]="form.get('mensaje')!"></app-mensaje-error>

          </div>
       </div>

        <div class="margin-1-rem">
          <button class="btn institutional-button" (click)="enviarEmail()">Enviar</button>
        </div>
      </form>


    </div>

  </div>
