import { Component, Input, OnChanges, SimpleChanges, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Usuario } from 'src/app/models/usuario';
import { UsuariosServicesService } from 'src/app/services/usuarios.services.service';

@Component({
  selector: 'app-artistas',
  templateUrl: './artistas.component.html',
  styleUrls: ['./artistas.component.css']
})
export class ArtistasComponent implements OnInit, OnChanges {

  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  @Input()
  isBig = true;

  @Input()
  usuarios: Usuario[] = [];

  firstElement!: number;

  elementsPerPage: number = 20;

  lastElement!: number;

  constructor(private usuarioServices: UsuariosServicesService) {
   }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.firstElement = 0;

    this.lastElement = this.elementsPerPage;
  }

  handlePageEvent(e: PageEvent) {
    console.log(e.pageIndex)
    this.firstElement = this.elementsPerPage * e.pageIndex;

    this.lastElement = this.elementsPerPage + (this.elementsPerPage * e.pageIndex);
  }

}
