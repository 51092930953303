import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectUser, selectUserNombre,  } from '../utils/reduce/auth.selectors';
import { Usuario } from 'src/app/models/usuario';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryServiceService } from 'src/app/services/query-service.service';
import {FlatTreeControl} from '@angular/cdk/tree';
import { InsigniaService } from 'src/app/services/insignia.service';
import { InsigniaUsuario } from 'src/app/models/insignia-usuario';
import { CardsService } from 'src/app/services/cards.service';
import { StripeService } from 'src/app/services/stripe.service';
import { Donacion } from 'src/app/models/donacion';


@Component({
  selector: 'app-pagina-personal',
  templateUrl: './pagina-personal.component.html',
  styleUrls: ['./pagina-personal.component.css']
})
export class PaginaPersonalComponent implements OnInit {

  userId = '';

  user$!: Observable<Usuario>;
  usuario: Usuario = {
    nombre: '',
    apellido: '',
    email: '',
    descripcion: '',
    rol: [],
    categorias: [],
    facebook: '',
    instagram: '',
    twitter: ''
  }
  email = '';
  userImgURL:string = '';
  pathList: string[] = [];
  paginaPropia = false;
  comprasIsExpanded = false;
  eventIsExpanded = false;
  difundidoIsExpanded = false;
  donacionesIsExpanded = false;
  insigniasList: string[] = [];
  donacionHabilitada = false;
  usuariosDonados: Usuario[] = [];

  constructor(private store: Store, private imagenServices: ImagenesService, private _Activatedroute: ActivatedRoute, private queryServices: QueryServiceService, private router: Router, private insigniaServices: InsigniaService, private cardServices: CardsService, private stripeServices: StripeService) {
    this.user$ = this.store.select(selectUser);
  }

  expandir(element: string) {
    switch (element) {
      case 'compras':
        this.comprasIsExpanded = !this.comprasIsExpanded;
        break;
      case 'eventos':
        this.eventIsExpanded = !this.eventIsExpanded;
       break;
      case 'difundidos':
        this.difundidoIsExpanded = !this.difundidoIsExpanded;
        break;

      case 'donaciones':
        this.donacionesIsExpanded = !this.donacionesIsExpanded;
        break;

      default:
        break;
    }
  }

  ngOnInit(): void {
    this._Activatedroute.queryParams.subscribe((params) => {
      
      if (params['id']) {
        this.userId = atob(params['id']);
        this.getUsuarioData(this.userId);
        this.getImagen(this.userId, 'perfil');
        this.email = this.userId;

        this.cardServices.getCardData(this.email).subscribe((res) => {
          if (res.data()) {
            this.donacionHabilitada = true;
          }
        })

      } else {
        this.paginaPropia = true;
        this.email = sessionStorage.getItem('email') as string;
        
        this.getImagen(this.email, 'perfil');
        this.getDonaciones();
      }
      this.getPosts(this.email);

      this.getInsignias(this.email);

    });
  }

  getDonaciones(): void {
    this.stripeServices.getDonaciones(sessionStorage.getItem('email') as string).subscribe((res) => {
      res.forEach((row) => {
        this.queryServices.getUsuario((row.data() as Donacion).destinatario).subscribe((user) => {
          let us: Usuario;
          user.forEach((row) => {
            us = row.data() as Usuario;
            this.usuariosDonados.push(us);
          });
        });
      });
    });
  }

  getInsignias(email: string): void {
    this.insigniaServices.consultarInsigniasPorUsuario(email).subscribe((res) => {
      res.forEach((row) => {
        const iu: InsigniaUsuario = row.data() as InsigniaUsuario;
        this.insigniasList = [];
        this.imagenServices.obtenerImagen(iu.insignia, 'insignia').subscribe((url) => {
          this.insigniasList.push(url);
        })
      });

    });

  }

  getPosts(email: string): void {
    this.imagenServices.listAll(`post/${email.toLocaleUpperCase()}`).subscribe((res) => {
      this.pathList = [];
      res.items.forEach((row) => {
        this.pathList.push(row.fullPath);
      });
      this.sortList(this.pathList);
    });
  }

  recargarPost(value: boolean): void {
    if (value) {
      this.getPosts(this.email);
    }
  }

  sortList(list: any[]): void {
    list.sort((a,b) => {
      if(a < b) { return 1; }
      if(a > b) { return -1; }
      return 0;
    });
  }

  getImagen(userId: string, coleccion: string): void {
    this.imagenServices.obtenerImagen(userId, coleccion).subscribe((response) => {
      this.userImgURL = response;
    });
  }

  getUsuarioData(userId: string): void {
    this.queryServices.getUsuario(userId).subscribe((resp) => {
      resp.forEach((row) => {
        this.usuario = row.data() as Usuario;
      });
    });
  }

  navegar(ruta: string, idUser: string):void{
    if (idUser) {
      this.router.navigate([ruta],
        { queryParams: { id: btoa(idUser)} }
      );
    } else {
      this.router.navigate([ruta]);
    }
  }

}
