
<div class="position-relative col-12 div-center" >
  <div class="position-absolute portada">
    <img *ngIf="portadaImgUrl" [src]="portadaImgUrl"/>

  </div>


  <div class="position-absolute  row col-12 top" style="padding-bottom: 80px;">

    <div class="col-12  d-flex justify-content-center margint-bottom-20 ">
      <div class="d-flex justify-content-center">
        <img class="foto-perfil" *ngIf="!userImgURL" src="assets/icons/usuario.svg" />
        <img class="foto-perfil" *ngIf="userImgURL" [src]="userImgURL" alt="Imagen de perfil" />
      </div>

    </div>

    <div class="col-12  d-flex justify-content-center margint-bottom-20">
      <input hidden type="file" #uploadImg accept=".png, .jpg , .jpeg"  (change)="subirImagen($event, 'perfil', 'perfilURL')">
      <button for="inputFile" type="file" mat-button class="btn institutional-button col-2" (click)="uploadImg.click()" >Foto de perfil</button>
    </div>

    <div class="col-12  d-flex justify-content-center margint-bottom-20">
      <input hidden type="file" #uploadImgPortada accept=".png, .jpg , .jpeg"  (change)="subirImagen($event, 'portada', 'portadaUrl')">
      <button for="inputFile" type="file" mat-button class="btn institutional-button col-2" (click)="uploadImgPortada.click()" >Portada</button>
    </div>

    <div>
      <mat-tab-group>
        <mat-tab label="Información general">

          <form [formGroup]="form" class=" d-flex justify-content-center">

            <div class="col-4 row margin-top-20">
              <div class="col-12 d-flex justify-content-center margint-bottom-20">
                <p class="h5">Datos personales</p>
               </div>

              <div class="col-12 d-flex justify-content-center margint-bottom-20" >
                <div class="col-10 ">
                  <input type="text" class="form-control" name="" id="" placeholder="Nombre" formControlName="nombre">
                </div>
              </div>

              <div class="col-12 d-flex justify-content-center margint-bottom-20" >
                <div class="col-10 ">
                  <input type="text" class="form-control" name="" id="" placeholder="Apellido" formControlName="apellido">
                </div>
              </div>

              <div class="col-12 d-flex justify-content-center margint-bottom-20" >
                <div class="col-10 ">
                  <textarea type="text" class="form-control" name="" id="" placeholder="Descripción"  formControlName="descripcion" maxlength="150"></textarea>
                </div>
              </div>

              <div class="col-12 d-flex justify-content-center margint-bottom-20" >
                <p class="h5">Agrega tus redes sociales</p>
              </div>

              <div class="col-12 d-flex justify-content-center margint-bottom-20" >
                <div class="col-10 ">
                  <input type="text" class="form-control" name="" id="" placeholder="Facebook" formControlName="facebook">
                </div>
              </div>

              <div class="col-12 d-flex justify-content-center margint-bottom-20" >
                <div class="col-10 ">
                  <input type="text" class="form-control" name="" id="" placeholder="Instagram" formControlName="instagram">
                </div>
              </div>

              <div class="col-12 d-flex justify-content-center margint-bottom-20" >
                <div class="col-10 ">
                  <input type="text" class="form-control" name="" id="" placeholder="Twitter" formControlName="twitter">
                </div>
              </div>

            </div>
          </form>


          <div class="col-12 d-flex justify-content-center margint-bottom-20" *ngIf="user.rol.includes('1')">
            <p class="h5">Categorias</p>
          </div>

          <div class="row col-12 d-flex justify-content-center margint-bottom-20" *ngIf="user.rol.includes('1')">


            <div class="row col-12 d-flex justify-content-center margint-bottom-20" >
              <div class="col-1 d-flex justify-content-center">
                <button mat-fab aria-label="" class="circle col-12 musica-theme" [ngClass]="{'opacity-half': !user!.categorias.includes(1)}"
                  (click)="seleccionarCategoria(1)">
                  <img class="circle  col-12" style="background: none;" src="assets/icons/musica.svg" />
                </button>
              </div>

              <div class="col-1 d-flex justify-content-center">
                <button mat-fab aria-label="" class="circle col-12 cine-theme" [ngClass]="{'opacity-half': !user!.categorias.includes(2)}"
                  (click)="seleccionarCategoria(2)">
                  <img class="circle  col-12" style="background: none;" src="assets/icons/cine.svg" />
                </button>
              </div>

              <div class="col-1 d-flex justify-content-center">
                <button mat-fab aria-label="" class="circle col-12 literatura-theme" [ngClass]="{'opacity-half': !user!.categorias.includes(3)}"
                  (click)="seleccionarCategoria(3)">
                  <img class="circle  col-12" style="background: none;" src="assets/icons/literatura.svg" />
                </button>
              </div>

              <div class="col-1 d-flex justify-content-center">
                <button mat-fab aria-label="" class="circle col-12 diseno-theme" [ngClass]="{'opacity-half': !user!.categorias.includes(4)}"
                  (click)="seleccionarCategoria(4)">
                  <img class="circle  col-12" style="background: none;" src="assets/icons/desing.svg" />
                </button>
              </div>

            </div>

            <div class="row col-12 d-flex justify-content-center margint-bottom-20">


              <div class="col-1 d-flex justify-content-center">
                <button mat-fab aria-label="" class="circle col-12 gastronomia-theme" [ngClass]="{'opacity-half': !user!.categorias.includes(5)}"
                  (click)="seleccionarCategoria(5)">
                  <img class="circle  col-12" style="background: none;" src="assets/icons/gastronomia.svg" />
                </button>
              </div>

              <div class="col-1 d-flex justify-content-center">
                <button mat-fab aria-label="" class="circle col-12 artesania-theme" [ngClass]="{'opacity-half': !user!.categorias.includes(6)}"
                  (click)="seleccionarCategoria(6)">
                  <img class="circle  col-12" style="background: none;" src="assets/icons/artesania.svg" />
                </button>
              </div>

              <div class="col-1 d-flex justify-content-center">
                <button mat-fab aria-label="" class="circle col-12 arte-digital-theme" [ngClass]="{'opacity-half': !user!.categorias.includes(7)}"
                  (click)="seleccionarCategoria(7)">
                  <img class="circle  col-12" style="background: none;" src="assets/icons/arte_digital.svg" />
                </button>
              </div>
            </div>

          </div>

          <div class="col-12  d-flex justify-content-center margint-bottom-40">
            <button mat-button class="btn institutional-button col-2" (click)="guardarCambios()">Guardar Cambios</button>
          </div>

        </mat-tab>
        <mat-tab label="Donaciones" *ngIf="user.rol.includes('1')">

          <div class="col-12 d-flex justify-content-center margint-bottom-20" style="margin-top: 20px;">
            <form [formGroup]="cardForm">

              <div class="col-12 d-flex justify-content-center margint-bottom-20" >
                <p class="h5">Donaciones</p>
              </div>

              <div class="col-12 d-flex justify-content-center margint-bottom-20">
                <h5 style="color: red;">Para poder habilitar las donaciones es necesario registrar los siguientes datos</h5>
              </div>


              <div class="col-12 d-flex justify-content-center margint-bottom-20" >
                <div class="col-10 ">
                  <input type="text" class="form-control" name="" id="" placeholder="Nombre completo" formControlName="nombre">
                </div>
              </div>

              <div class="col-12 d-flex justify-content-center margint-bottom-20" >
                <div class="col-10 ">
                  <input type="tel" class="form-control" (keyup)="validateOnlyNumbers($event, 'cardNumber')" maxlength="16" name="" id="" placeholder="Número de tarjeta" formControlName="cardNumber">
                </div>
              </div>

              <div class="col-12 d-flex justify-content-center margint-bottom-20" >
                <div class="col-10 ">
                  <input type="tel" (keyup)="validateOnlyNumbers($event, 'clabe')" class="form-control" maxlength="18" name="" id="" placeholder="CLABE" formControlName="clabe">
                </div>
              </div>

            </form>
          </div>



          <div class="col-12  d-flex justify-content-center margint-bottom-20">
            <button mat-button [disabled]="!cardForm.valid" class="btn institutional-button col-2" (click)="habilitarDonaciones()" >Habilitar donaciones</button>
          </div>

          <div class="col-12 d-flex justify-content-center" style="margin-top: 40px;">
            <div class="col-12 d-flex justify-content-center">
              <p class="h5">Saldo Actual: ${{saldo.monto}}</p>
            </div>
          </div>

          <div class="col-12  d-flex justify-content-center margint-bottom-20">
            <button mat-button [disabled]="saldoUsuario == 0" class="btn institutional-button col-2" (click)="enviarEmails()" >Solicitar retiro de donaciones</button>
          </div>

          <div class="col-12 d-flex justify-content-center margint-bottom-20" style="margin-top: 20px;">
            <div class="col-12 d-flex justify-content-center margint-bottom-20">
              <h5 style="color: red;">Este proceso puede tardar hasta 72 Horas</h5>
            </div>
          </div>


        </mat-tab>
        <mat-tab label="Ubicación" *ngIf="user.rol.includes('1')">


          <div class="col-12  d-flex justify-content-center margint-bottom-20" style="margin-top: 20px;">
            <p class="h5">Editar Ubicación</p>
          </div>

          <div class="col-12  d-flex justify-content-center margint-bottom-20">
            <p class="h6">Seleccione su ubicación</p>
          </div>


          <div class="col-12 d-flex justify-content-center" *ngIf="user.rol.includes('1')">


            <div class="mapa">
              <app-mapa  [width]="'100%'" [height]="'500px'" [mode]="'EDIT'" [nombre]="form.controls['nombre'].value + ' ' + form.controls['apellido'].value"></app-mapa>
            </div>
          </div> </mat-tab>
      </mat-tab-group>
    </div>

    <div style="height: 150px;">

    </div>

  </div>

</div>





