import { createReducer, on } from '@ngrx/store';

import { CategoriasActios } from './categorias.actions';
import { Categoria } from './categoria';

export const initialState: Categoria = {
  categorias: [],
  nombre: ''
};

export const setCategoriasReducer = createReducer(
  initialState,
  on(CategoriasActios.setCategorias, (_state, newState) => {
    let categorias: number[] = []

    if (newState.categoria != 0) {

    } else {
      categorias = _state.categorias;
    }

    switch (newState.categoria) {
      case 0:
        categorias = _state.categorias;
        break;

      case -1:
        categorias = categorias;
        break;

      default:
        categorias = [..._state.categorias];
        if (categorias.includes(newState.categoria)) {
          categorias = categorias.filter((row) => {
            if (newState.categoria  != row) {
              return row;
            } else {
              return;
            }
          });
        } else {
          categorias.push(newState.categoria);
        }
        categorias.sort();
        break;
    }
   return {categorias: categorias, nombre: newState.nombre};
  } )
);

export const deleteCategoria = createReducer(
  initialState,
  on(CategoriasActios.deleteCategorias, (_state, newState) => {
    let categorias = [..._state.categorias];

    categorias.filter((row) => {
      if (newState.categoria  != row) {
        return row;
      } else {
        return;
      }
    })

   return {categorias: categorias, nombre: _state.nombre};;
  } )
);

export const initCategorias = createReducer(
  initialState,
  on(CategoriasActios.initCategorias, (_state, newState) => {
    _state = newState.categoria;
   return _state;
  } )
);


