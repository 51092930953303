<div class="d-flex justify-content-center text-center" style="margin-top: 100px;">



  <div class="width-25-rem">
    <div class="margin-1-rem">
      <p class="h6">Registro de nuevo usuario</p>
    </div>



    <form [formGroup]="form" style="padding-top: 20px;">

      <mat-radio-group aria-label="Select an option" formControlName="tipoUsuario" class="d-flex justify-content-around">
        <mat-radio-button value="1" class="margin-05-rem">Creativo</mat-radio-button>
        <mat-radio-button value="2" class="margin-05-rem">Mecenas</mat-radio-button>
        <mat-radio-button value="3" class="margin-05-rem">Fan</mat-radio-button>
      </mat-radio-group>


      <div class="text-left margin-1-rem" *ngIf="form.controls['tipoUsuario'].value" style="padding: 1rem; background: rgba(232, 232, 232, 0.53);">
        <div *ngIf="form.controls['tipoUsuario'].value === '1'" style="font-size: large;">
          <p class="h6">Eres un artista o creador y quieres:</p>
          <p class="small">- Solicitar apoyos de donativos.</p>
          <p class="small">- Vender productos en tu tienda online.</p>
          <p class="small">- Programar presentaciones artísticas.</p>
          <p class="small">- Solicitar promoción para tus proyectos.</p>
        </div>
        <div *ngIf="form.controls['tipoUsuario'].value === '2'" style="font-size: large;">
          <p class="h6">Eres un mecenas y quieres:</p>
          <p class="small">- Apoyar con donativos.</p>
          <p class="small">- Comprar productos de los creadores.</p>
          <p class="small">- Asistir a presentaciones de los creadores.</p>
          <p class="small">- Promover proyectos de los creadores.</p>
        </div>
        <div *ngIf="form.controls['tipoUsuario'].value === '3'" style="font-size: large;">
          <p class="h6">Eres un fan y quieres:</p>
          <p class="small">- Comprar productos de los creadores.</p>
          <p class="small">- Asistir a presentaciones de los creadores.</p>
          <p class="small">- Promover proyectos de los creadores.</p>
        </div>
      </div>

      <div>
        <div class="margin-1-rem">
          <input type="text" class="form-control" name="" id="" placeholder="Nombre" formControlName="nombre">

          <app-mensaje-error [control]="form.get('nombre')!"></app-mensaje-error>

        </div>
        <div class="margin-1-rem">
          <input type="text" class="form-control" name="" id="" placeholder="Apellido" formControlName="apellido">
          <app-mensaje-error [control]="form.get('apellido')!"></app-mensaje-error>

        </div>
        <div class="margin-1-rem">
          <input type="text" class="form-control" name="" id="" placeholder="Correo electrónico" formControlName="email">
          <app-mensaje-error [control]="form.get('email')!"></app-mensaje-error>

        </div>
        <div *ngIf="form.controls['tipoUsuario'].value == 1" class="margin-1-rem div-center  text-center">

          <div>
            <div class="margin-1-rem">
              Selecciona una categoría o varias categorías
            </div>
            <div class="explorer-menu justify-content-center text-center">
              <div class="margin-1-rem div-center">
                <button mat-fab aria-label="" class="circle-little-icon musica-theme" [ngClass]="{'opacity-half': !this.categoriasSeleccionadas.includes(1)}"
                (click)="seleccionarCategoria(1)">
                  <img class="nano-icon" src="assets/icons/musica.svg" />
                </button>
              <div class="margin-1-rem">
                Música
              </div>
              </div>

              <div class="margin-1-rem div-center">
                <button mat-fab aria-label="" class="circle-little-icon cine-theme" [ngClass]="{'opacity-half': !this.categoriasSeleccionadas.includes(2)}"
                (click)="seleccionarCategoria(2)">
                  <img class="nano-icon" src="assets/icons/cine.svg"/>
                </button>
                <div class="margin-1-rem">
                  Cine
                </div>
              </div>

              <div class="margin-1-rem div-center">
                <button mat-fab aria-label="" class="circle-little-icon literatura-theme" [ngClass]="{'opacity-half': !this.categoriasSeleccionadas.includes(3)}"
                (click)="seleccionarCategoria(3)">
                  <img class="nano-icon" src="assets/icons/literatura.svg"/>
                </button>
                <div class="margin-1-rem">
                  Literatura
                </div>
              </div>

              <div class="margin-1-rem div-center">
                <button mat-fab aria-label="" class="circle-little-icon diseno-theme" [ngClass]="{'opacity-half': !this.categoriasSeleccionadas.includes(4)}"
                (click)="seleccionarCategoria(4)">
                  <img class="nano-icon" src="assets/icons/desing.svg"/>
                </button>
                <div class="margin-1-rem">
                  Diseño
                </div>
              </div>

            </div>

            <div class="explorer-menu justify-content-center text-center">

              <div class="margin-1-rem div-center">
                <button mat-fab aria-label="" class="circle-little-icon gastronomia-theme" [ngClass]="{'opacity-half': !this.categoriasSeleccionadas.includes(5)}"
                (click)="seleccionarCategoria(5)">
                  <img class="nano-icon" src="assets/icons/gastronomia.svg"/>
                </button>
                <div class="margin-1-rem">
                  Gastronomía
                </div>
              </div>

              <div class="margin-1-rem div-center">
                <button mat-fab aria-label="" class="circle-little-icon artesania-theme" [ngClass]="{'opacity-half': !this.categoriasSeleccionadas.includes(6)}"
                (click)="seleccionarCategoria(6)">
                  <img class="nano-icon" src="assets/icons/artesania.svg"/>
                </button>
                <div class="margin-1-rem">
                  Artesanía
                </div>
              </div>

              <div class="margin-1-rem div-center">
                <button mat-fab aria-label="" class="circle-little-icon arte-digital-theme" [ngClass]="{'opacity-half': !this.categoriasSeleccionadas.includes(7)}"
                (click)="seleccionarCategoria(7)">
                  <img class="nano-icon" src="assets/icons/arte_digital.svg"/>
                </button>
                <div class="margin-1-rem">
                  Arte digital
                </div>
              </div>

            </div>
          </div>

        </div>
        <div class="margin-1-rem">
          <textarea type="text" class="form-control" name="" id="" placeholder="Presentación"  formControlName="descripcion" maxlength="150"></textarea>
          <app-mensaje-error [control]="form.get('descripcion')!"></app-mensaje-error>


        </div>
        <div class="margin-1-rem">
          <input type="password" class="form-control" name="" id="" placeholder="Contraseña" formControlName="password">
          <app-mensaje-error [control]="form.get('password')!"></app-mensaje-error>

        </div>
        <div class="margin-1-rem">
          <input type="password" class="form-control" name="" id="" placeholder="Repetir contraseña" formControlName="passwordConfirm">
          <app-mensaje-error [control]="form.get('passwordConfirm')!"></app-mensaje-error>

        </div>
     </div>

      <div class="margin-1-rem">
        <button class="btn institutional-button" [disabled]="!validarForm() || enEspera " (click)="registrarCuenta()">Registrarme</button>
      </div>
    </form>

      <div class="margin-1-rem">
      ¿Ya tienes cuenta? <a routerLink="/login">Iniciar sesión</a>
      </div>
  </div>

</div>
