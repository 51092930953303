<div style="height: 150px;">

</div>

<div class="row col-12">
  <div class="col-1"></div>
  <div class="col-10">

    <p class="h5 text-center">
      AVISO LEGAL.
    </p>

    <p class="h5 text-center">
      POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS.
    </p>

    <p class="h6">
      Creatópolis es una plataforma de internet propiedad de OV Estudio, con domicilio en Av. Adolfo Ruiz Cortines No. 1352 D-8, Colonia Tamborrel, Xalapa, Veracruz, México. En cumplimiento a lo establecido en la Ley Federal de Datos Personales en Posesión de los Particulares, que tiene entre sus objetivos la protección de la información personal proporcionada por cada individuo. De acuerdo a lo anterior, Creatópolis ha establecido las siguientes Políticas para proteger dicha información.
    </p>



    <p>
      1.- Descripción General de la Política de Privacidad de Creatópolis.

      Creatópolis se compromete a respetar su privacidad. En general, usted puede visitar creatopolis.org sin revelar ningún tipo de información acerca de su persona. Creatópolis y sus proveedores de estadísticas recopilan información referente a los visitantes de nuestro sitio web como el nombre de dominio, número de visitantes y tiempo promedio que se invirtió para medir el uso y mejorar el contenido de nuestros sitios. Analizamos esta información para conocer las tendencias y estadísticas con la finalidad de ayudarnos a proporcionarle el mejor servicio posible. Es posible que nuestro sitio web proporcione enlaces a sitios de terceros. Debido a que no controlamos esos sitios web, lo invitamos a revisar las políticas de privacidad de los mismos. Creatópolis no es responsable de las prácticas de privacidad ni del contenido de los sitios web de terceros.

    </p>

    <p>
      2.- Información Personal Solicitada.

      Creatópolis solicitará información de carácter personal a los usuarios de los servicios a través de nuestro Sitio de Internet y Centro de Atención Telefónica, con el fin de generar registros de usuarios que deseen colaborar con la plataforma como usuarios creativos, usuarios fans o usuarios mecenas para que puedan mostrar su trabajo, comprar productos y/o servicios o apoyar a artistas y creadores en el sitio. El tipo de información que se solicita a los usuarios incluye, de manera enunciativa más no limitativa: nombre, dirección de correo electrónico (e-mail), fecha de nacimiento, domicilio, teléfono, ámbito creativo, sector económico, entre otros datos únicamente de contacto.

      El titular de los datos, al registrarse, otorga su pleno consentimiento libre y voluntariamente de proporcionar los datos personales que le sean requeridos, en el entendido de que si el usuario decidiera no proporcionar información obligatoria, este no podrá acceder a los servicios que efectivamente requieran dicha información, sin embargo, podrá tener acceso a todos los demás servicios que no requieran dicha Información.

      Creatópolis  en ningún caso y bajo ninguna circunstancia almacena información de datos personales sensibles como origen racial o étnico, estado de salud actual, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual, etc.

      Asimismo, recolectamos las direcciones correo electrónico de aquellos quienes se comunican con nosotros por esta vía, información agregada de cuales páginas son las que visita, así como cualquier otra información que de manera voluntaria nos proporciona, tal como la relativa a encuestas o registros en el sitio.

      La información que proporcione como usuario de nuestro Sitio en Internet podrá ser usada para: contactarlo para cualquier tipo de aviso relacionado con los servicios que ofrecemos, detectar o prevenir la comisión de posibles delitos cibernéticos, para llevar a cabo encuestas a clientes, administrar la relación con el cliente y mejorar el contenido del sitio. Si se registra en nuestro “Boletín Creatópolis” para recibir noticias electrónicas de Creatópolis entonces usted quedará registrado de forma automática en nuestra lista de envíos por e-mail, y en cada Boletín serán incluidas las instrucciones sobre cómo «eliminar su nombre y correo electrónico» de dicha lista.

      La información personal que como usuario de nuestro Sitio de Internet y Centro de Atención Telefónica nos proporcione deberá ser veraz y corresponder a su situación actual, para poder brindarle un mejor servicio.

    </p>

    <p>
      3.- Privacidad y Protección de Datos Personales.

      Creatópolis utiliza tecnología avanzada para los procesos de protección de la información proporcionada por los titulares de los datos personales. Esta tecnología cifra, codifica y previene la intercepción de la información suministrada por Internet.

      Creatópolis establece y mantiene medidas de seguridad  en todos los sectores (administrativos, técnicos y físicos) que permiten proteger los datos personales contra: daños, pérdida o alteración, destrucción, o el uso, acceso o tratamiento no autorizado.

      El sitio de Internet de Creatópolis contiene links, hipervínculos o hipertextos, anuncios, botones y herramientas de búsqueda en la red mundial que al ser oprimidos o utilizados por los usuarios conectan a otros portales o sitios de Internet propiedad de terceros. La información que el usuario llegase a proporcionar a través de esos sitios o portales no se encuentra cubierta o contemplada por este aviso de privacidad y su manejo o utilización no es responsabilidad de Creatópolis, por lo que recomendamos a nuestros usuarios verificar los avisos y políticas de privacidad aplicables a cada sitio.

      Reafirmando lo anterior, nos comprometemos a no transferir su información personal a terceros sin su consentimiento, salvo las excepciones previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, así como a realizar esta transferencia en los términos que fija la ley.

      Artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
    </p>

    <p>
      Detalles del Artículo 37:
    </p>

    <p>
      Artículo 37.- Las transferencias nacionales o internacionales de datos podrán llevarse a cabo sin el consentimiento del titular cuando se dé alguno de los siguientes supuestos:
    </p>

    <p>I.    Cuando la transferencia esté prevista en una Ley o Tratado en los que México sea parte;</p>

    <p>II.  Cuando la transferencia sea necesaria para la prevención o el diagnóstico médico, la prestación de asistencia sanitaria, tratamiento médico o la gestión de servicios sanitarios;</p>

    <p>III. Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control común del responsable, o a una sociedad matriz o a cualquier sociedad del mismo grupo del responsable que opere bajo los mismos procesos y políticas internas;</p>

    <p>IV. Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del titular, por el responsable y un tercero;</p>

    <p>V. Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la procuración o administración de justicia;</p>

    <p>VI. Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial, y</p>

    <p>VII. Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el responsable y el titular.</p>

    <p>
      4.- Seguridad y Calidad.

    Creatópolis se compromete a llevar a cabo los pasos adecuados para proteger la información de identificación individual que usted proporcione. Usted puede ayudar a Creatópolis a actualizar y a mantener la precisión de cualquier información personal proporcionando a Creatópolis una notificación por correo electrónico a: contacto@creatopolis.org, con cualquier cambio a su dirección, cargo, número telefónico o dirección de e-mail.

    </p>

    <p>
      5.- Eliminación de la  Información.

    En Creatópolis nos reservamos el derecho de enviarle ciertos comunicados relacionados con nuestros servicios, por ejemplo: notificaciones de servicio, promociones  y  boletines especiales, los cuales se consideran parte de la suscripción de Creatópolis, aunque todos nuestros usuarios pueden optar por  no recibirlos, enviando un mail a contacto@creatopolis.org con: Cancelar Suscripción en el asunto, aunque esto no implica que los datos almacenados sean eliminados.

    </p>

    <p>
      6.- Cambios a la Política de Privacidad.

      Creatópolis se reserva el derecho de modificar total o parcialmente los términos y condiciones de esta Política de Privacidad y Protección de Datos, por lo que le recomendamos visitar nuestro sitio de Internet periódicamente y consultar nuestras políticas actualizadas.

    </p>
    <p>
      7.- Cookies, Web Beacons y Otras Tecnologías de Control.

      Creatópolis utiliza cookies con el objeto de poder comprender qué áreas de nuestro sitio web son de interés para usted y proporcionar así un mejor servicio con información adecuada. Una cookie es un elemento de datos que puede enviar el sitio web a su navegador y se puede almacenar posteriormente en su sistema. Usted puede configurar su navegador para notificar cuando recibe una cookie, lo que le brinda la opción de decidir aceptarla o no. Estas cookies no controlan la información individual, más bien registra el tráfico del sitio web de manera anónima. Creatópolis puede utilizar cookies cuando usted se registra para uno de nuestros programas web o extranet. En este caso, se almacenará una cookie con información útil, incluyendo su información personal, lo que permite a nuestro sitio web recordarlo cuando regresa a visitar la página, aunque sólo los sitios web de Creatópolis pueden leer esa información.

      Algunos sitios web de Creatópolis también pueden utilizar tecnologías «web beacon». Cuando un visitante accede a una página en el sitio web de Creatópolis con un web beacon, se genera una nota anónima de esa visita la cual podemos procesar nosotros o nuestros proveedores. Estos web beacons trabajan junto con las cookies para permitirnos conocer qué partes de nuestro sitio web son de interés para nuestros visitantes y para ayudarnos a proporcionarle información adecuada.

      Si desactiva las cookies, las tecnologías web beacon seguirán detectando las visitas anónimas, pero las notificaciones que generan no se podrán asociar con otro tipo de información anónima ni con la información que se puede identificar personalmente y ésta se esquiva.

    </p>

    <p>
      8. Aceptación de Términos.

      De acuerdo a lo anteriormente mencionado esta declaración de Confidencialidad / Privacidad está sujeta a los términos y condiciones del sitio de Internet de Creatópolis www.creatopolis.org antes descritos, lo cual constituye un acuerdo legal entre usted como usuario y Creatópolis,  Si como usuario utiliza los servicios de www.creatopolis.org significa que ha leído, entendido y acordado los términos antes expuestos. Si no está de acuerdo con ellos, no deberá proporcionar ninguna información personal, ni utilizar los servicios del sitio www.creatopolis.org.
    </p>

    <p>
      9.- Vigilancia o Preguntas.

      Creatópolis agradece cualquier comentario o pregunta sobre esta política. Las preguntas relacionadas con nuestra política se deberán dirigir al correo electrónico: contacto@creatopolis.org.
    </p>


  </div>
</div>
