import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Usuario } from 'src/app/models/usuario';

export const selectUsers = createFeatureSelector<Usuario>('usuario');

export const selectUser = createSelector(
  selectUsers,
  (usuario) => {
    return usuario;
  }
);

export const selectUserNombre = createSelector(
  selectUsers,
  (usuario) => {
    return usuario.nombre;
  }
);

export const selectUserApellido = createSelector(
  selectUsers,
  (usuario) => {
    return usuario!.apellido;
  }
);
