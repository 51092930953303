import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { MapaComponent } from './components/mapa/mapa.component';
import { LoginComponent } from './components/login/login.component';
import { RegistroComponent } from './components/registro/registro.component';
import { CategoriasComponent } from './components/categorias/categorias.component';
import { ResumeCardComponent } from './components/resume-card/resume-card.component';
import { ArtistasComponent } from './components/artistas/artistas.component';
import { ArtistaPageComponent } from './components/artista-page/artista-page.component';
import { HttpClientModule } from '@angular/common/http';
import {MatIconModule} from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { GoogleMapsModule} from '@angular/google-maps';
import { PaginaPersonalComponent } from './components/pagina-personal/pagina-personal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditComponent } from './components/pagina-personal/edit/edit.component';
import { usuarioSaveReducer } from './components/utils/reduce/auth.reducer';
import { StoreModule } from '@ngrx/store';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { VerificacionComponent } from './components/login/verificacion/verificacion.component';
import { mensajeErrorComponent } from "./components/mensajes-error/mensaje-error.component";
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { provideStorage,getStorage } from '@angular/fire/storage';
import { setCategoriasReducer } from './components/utils/reduce/categorias/categoria.reducer';
import * as firebase from 'firebase/app';
import { DifundirComponent } from './components/difundir/difundir.component';
import { TiendaComponent } from './components/tienda/tienda.component';
import { ArticuloComponent } from './components/tienda/articulo/articulo.component';
import {MatDialogModule} from '@angular/material/dialog';
import { AgregarComponent } from './components/tienda/agregar/agregar.component';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatCardModule} from '@angular/material/card'
import { AgregarEventoComponent } from './components/difundir/agregar-evento/agregar-evento.component';
import { MatNativeDateModule } from '@angular/material/core';
import { EventoComponent } from './components/difundir/evento/evento.component';
import { DeleteComponent } from './components/dialogs/delete/delete.component';
import { EditarEventoComponent } from './components/difundir/editar-evento/editar-evento.component';
import { AsistirComponent } from './components/asistir/asistir.component';
import { SubirPostComponent } from './components/header/subir-post/subir-post.component';
import { PostComponent } from './components/pagina-personal/post/post.component';
import { OpenComponent } from './components/pagina-personal/open/open.component';
import { DonacionesComponent } from './components/donaciones/donaciones.component';
import { DonacionComponent } from './components/donaciones/donacion/donacion.component';
import { CurrencyMaskDirective } from './directives/currency-mask.directive';
import { InsigniaComponent } from './components/dialogs/insignia/insignia.component';
import { MatTreeModule} from '@angular/material/tree';
import { AgregarAsistirComponent } from './components/asistir/agregar-asistir/agregar-asistir.component';
import { MatSelectModule } from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTabsModule} from '@angular/material/tabs';
import { PanelComponent } from './components/asistir/panel/panel.component';
import {MatTableModule} from '@angular/material/table';
import { AvisoComponent } from './components/aviso/aviso.component';
import {FunctionsModule} from '@angular/fire/functions';
import { ContactoComponent } from './components/contacto/contacto.component';
import { MainComponent } from './components/main/main.component';


firebase.initializeApp(environment.firebase);


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        MapaComponent,
        LoginComponent,
        RegistroComponent,
        CategoriasComponent,
        ResumeCardComponent,
        ArtistasComponent,
        ArtistaPageComponent,
        PaginaPersonalComponent,
        EditComponent,
        mensajeErrorComponent,
        VerificacionComponent,
        DifundirComponent,
        TiendaComponent,
        ArticuloComponent,
        AgregarComponent,
        AgregarEventoComponent,
        EventoComponent,
        DeleteComponent,
        AsistirComponent,
        EditarEventoComponent,
        DeleteComponent,
        SubirPostComponent,
        PostComponent,
        OpenComponent,
        DonacionesComponent,
        DonacionComponent,
        CurrencyMaskDirective,
        InsigniaComponent,
        AgregarAsistirComponent,
        PanelComponent,
        AvisoComponent,
        ContactoComponent,
        MainComponent

    ],
    providers: [],
    bootstrap: [AppComponent],
    imports: [
        HttpClientModule,
        BrowserModule,
        AngularFireAuthModule,
        AppRoutingModule,
        MatIconModule,
        FunctionsModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatButtonModule,
        GoogleMapsModule,
        MatButtonModule,
        MatNativeDateModule,
        MatCardModule,
        MatDatepickerModule,
        MatMenuModule,
        BrowserAnimationsModule,
        StoreModule.forRoot({ usuario: usuarioSaveReducer, categoria: (setCategoriasReducer) }),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        provideDatabase(() => getDatabase()),
        provideStorage(() => getStorage()),
        MatDialogModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatTreeModule,
        MatPaginatorModule,
        MatTabsModule,
        MatTableModule
    ],
    exports: [CurrencyMaskDirective]
})
export class AppModule { }
