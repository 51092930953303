import { Component, OnInit, inject } from '@angular/core';
import { Auth, User, authState } from '@angular/fire/auth';
import { filter, tap } from 'rxjs';
import { UsuariosServicesService } from 'src/app/services/usuarios.services.service';

@Component({
  selector: 'app-verificacion',
  templateUrl: './verificacion.component.html',
  styleUrls: ['./verificacion.component.css']
})
export class VerificacionComponent  {
  user: User | null = null; 


  constructor(private servicios: UsuariosServicesService) { 
    this.servicios.userState$
    .pipe(
      filter((authState)=>servicios !== null),
      tap((user)=>{
        this.user = user;
        if(this.isUserNotVerified(user!)){
          this.servicios.cerrarSesion();
        }
      })
    )
      .subscribe();
  }

  private isUserNotVerified(user: User): boolean {
    return !user.emailVerified;
  }


}
