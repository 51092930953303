import { createActionGroup, props } from '@ngrx/store';
import { Categoria } from './categoria';



export const CategoriasActios = createActionGroup({
  source: 'categorias',
  events: {
    'set Categorias': props<{ categoria: number, nombre: string }>(),
    'delete Categorias': props<{ categoria: number }>(),
    'init Categorias': props<{ categoria: Categoria }>()
  },
})
