import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { ShowDialog } from '../agregar-asistir/agregar-asistir.component';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { ShowsService } from 'src/app/services/shows.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit {

  @Output()
  outPutEvent = new EventEmitter<boolean>();

  @Input()
  show: ShowDialog = {
    show: {
      titulo: '',
      fechaCreacion: '',
      urlImagen: '',
      autor: '',
      horarios: [],
      fechaEventoInicio: '',
      fechaEventoFinal: '',
      boleteraUrl: ''
    },
    imgUrl: '',
    id: ''
  }
  paginaPropia = false;

  constructor(private showServices: ShowsService, private _Activatedroute: ActivatedRoute, private imagenServices: ImagenesService) { }

  ngOnInit(): void {
    this._Activatedroute.queryParams.subscribe((params) => {
      this.paginaPropia = params['id'] === sessionStorage.getItem('email') as string;
    });
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {

    let inicialDate = new Date(`${this.show.show.fechaEventoInicio.split("/")[1]}/${this.show.show.fechaEventoInicio.split("/")[0]}/${this.show.show.fechaEventoInicio.split("/")[2]}`);
    let finalDate = new Date(`${this.show.show.fechaEventoFinal.split("/")[1]}/${this.show.show.fechaEventoFinal.split("/")[0]}/${this.show.show.fechaEventoFinal.split("/")[2]}`);
    if (view === 'month') {
      const date = cellDate.getDate();
      if (cellDate.getMonth() === inicialDate.getMonth() || cellDate.getMonth() === finalDate.getMonth()) {
        return date >= inicialDate.getDate() && date <= finalDate.getDate() ? 'rango' : '';
      } else {
        return '';
      }
    }

    return '';
  };

  delete(): void {
    Swal.fire({
      title: 'Quieres eliminar este evento',
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {

      if (result.isConfirmed) {
        Swal.fire('Se ha eliminado el evento', '', 'success')

        this.showServices.eliminarShow(this.show.id).subscribe((res) => {
          this.outPutEvent.emit(true);
          this.imagenServices.deleteImagen(this.show.imgUrl).subscribe();
        });
      }
      // else if (result.isDenied) {
      //   Swal.fire('Changes are not saved', '', 'info')
      // }
    })
  }

}
