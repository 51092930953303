import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import { Usuario } from 'src/app/models/usuario';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { QueryServiceService } from 'src/app/services/query-service.service';
import { AgregarComponent, DataDialog } from './agregar/agregar.component';
import { Articulo } from 'src/app/models/articulo';
import { ArticulosServices } from 'src/app/services/articulos.service';
import Swal from 'sweetalert2';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tienda',
  templateUrl: './tienda.component.html',
  styleUrls: ['./tienda.component.css']
})
export class TiendaComponent implements OnInit {

  usuario: Usuario = {
    nombre: '',
    apellido: '',
    email: '',
    descripcion: '',
    rol: [],
    categorias: [],
    facebook: '',
    instagram: '',
    twitter: ''
  }

  articulosList: DataDialog[] = [];
  userId = '';
  userImgURL:string = '';
  portadaImgUrl: string = '';
  paginaPropia = false;

  form: FormGroup = new FormGroup({});

  constructor(private _location: Location, private imagenServices: ImagenesService, private _Activatedroute: ActivatedRoute, private queryServices: QueryServiceService, private articulosServices: ArticulosServices, private router: Router, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('')
    });

    this._Activatedroute.queryParams.subscribe((params) => {
      this.userId = atob(params['id']);
      this.paginaPropia = this.userId === sessionStorage.getItem('email') as string;
      this.getArticulos(this.userId);
      this.getUsuarioData(this.userId);
      this.getImagen(this.userId, 'portada');
      this.getImagen(this.userId, 'perfil');
    });
  }

  backClicked() {
    this._location.back();
  }

  getArticulos(userId: string): void {
    this.articulosServices.getArticulos(userId).subscribe((res) => {
      this.articulosList = [];
      res.forEach((row) => {
        this.articulosList.push({
          articulo: row.data() as Articulo,
          id: row.id,
          imgUrl: ''
        });
      });
      this.filtrar();
    });
  }

  getImagen(userId: string, coleccion: string): void {
    this.imagenServices.obtenerImagen(userId, coleccion).subscribe((response) => {
      switch (coleccion) {
        case 'portada':
          this.portadaImgUrl = response;
          break;
        case 'perfil':
          this.userImgURL = response;
        break;
        default:
          break;
      }
    });
  }

  getUsuarioData(userId: string): void {
    this.queryServices.getUsuario(userId).subscribe((resp) => {
      resp.forEach((row) => {
        this.usuario = row.data() as Usuario;
      });
    });
  }


  navegar(ruta: string, idUser: string):void{
    if (idUser) {
      this.router.navigate([ruta],
        { queryParams: { id: idUser } }
      );
    } else {
      this.router.navigate([ruta]);
    }
  }

  updateArticulo(data: {dataDialog: DataDialog, file: File}) {
    if (data.file != undefined) {
      this.imagenServices.subirImagen(data.file, data.dataDialog.articulo.nombre.replaceAll(' ', '').toLocaleUpperCase(), `articulo/${data.dataDialog.articulo.autor}`).subscribe((res) => {
        data.dataDialog.articulo.imagen = res.metadata.fullPath;
        this.articulosServices.actualizarArticulo(data.dataDialog.articulo , data.dataDialog.id).subscribe((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Se ha actualizado el artículo',
            text: '',
            confirmButtonText: 'Continuar'
          });
          this.getArticulos(this.userId);
        });
      });
    } else {
      this.articulosServices.actualizarArticulo(data.dataDialog.articulo , data.dataDialog.id).subscribe((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Se ha actualizado el artículo',
          text: '',
          confirmButtonText: 'Continuar'
        });
        this.getArticulos(this.userId);
      });
    }
  }

  deleteArticulo(dataDialog: DataDialog): void {
    this.articulosServices.eliminarArticulo(dataDialog.id).subscribe((res) => {
      this.imagenServices.deleteImagen(dataDialog.articulo.imagen);
      Swal.fire({
        icon: 'success',
        title: 'Se ha eliminado el artículo',
        text: '',
        confirmButtonText: 'Continuar'
      });
      this.getArticulos(this.userId);
    })
  }

  filtrar(): void {
    this.articulosList = this.articulosList.filter((articulo) => articulo.articulo.nombre.includes(this.form.controls['name'].value));
  }

  openDialog(): void {
    const dialogRef = this.dialog.open<AgregarComponent>(AgregarComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveArticulo(result.dataDialog, result.imagen);
      }
    });
  }


  saveArticulo(data: DataDialog, imagen: File): void {
    const articulo: Articulo = data.articulo;
    this.imagenServices.subirImagen(imagen, articulo.nombre.replaceAll(' ', '').toLocaleUpperCase(), `articulo/${articulo.autor}`).subscribe((res) => {
      articulo.imagen = res.metadata.fullPath;
      this.articulosServices.guardarArticulo(articulo, articulo.autor).subscribe((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Se ha registrado el nuevo artículo',
          text: '',
          confirmButtonText: 'Continuar'
        });
        this.getArticulos(this.userId);
      });
    });
  }
}
