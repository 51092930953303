import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Categoria } from './categoria';

export const selectCaregorias = createFeatureSelector<Categoria>('categoria');

export const selectCaregoriasList = createSelector(
  selectCaregorias,
  (categoria) => {
    return categoria;
  }
);

