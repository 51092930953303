import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Usuario } from 'src/app/models/usuario';
import { UsuariosServicesService } from 'src/app/services/usuarios.services.service';
import { selectUser } from '../../utils/reduce/auth.selectors';
import { QueryServiceService } from 'src/app/services/query-service.service';
import { ImagenesService } from 'src/app/services/imagenes.service';
import Swal from 'sweetalert2';
import { CardsService } from 'src/app/services/cards.service';
import { CardData } from 'src/app/models/cardData';
import { StripeService } from 'src/app/services/stripe.service';
import { Saldo } from 'src/app/models/saldo';
import { EmailService } from 'src/app/services/email.service';
import { UserActions } from '../../utils/reduce/auth.actions';



@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css', '../pagina-personal.component.css']
})
export class EditComponent implements OnInit {

  user: Usuario = {
    nombre: '',
    apellido: '',
    email: '',
    descripcion: '',
    categorias: [],
    rol: [],
    facebook: '',
    instagram: '',
    twitter: ''
  };

  cardNumber: string  = '';
  clabe: string = '';
  nombreCompleto: string = 'Fer';

  userImgURL:string = '';
  portadaImgUrl:string = '';
  saldoUsuario = 0;

  user$!: Observable<Usuario>;

  cardForm: FormGroup = new FormGroup({});

  form: FormGroup = new FormGroup({});
  categoriasSeleccionadas: number[]  | undefined = [];
  saldo: Saldo = {
    usuario: '',
    monto: '0'
  }

  constructor(private emailServices: EmailService,private usuarioServices: UsuariosServicesService, private router: Router, private store: Store, private queryServices: QueryServiceService, private imagenServices: ImagenesService, private formBuilder: FormBuilder, private cardServices: CardsService, private stripeServices: StripeService) {
    this.user$ = this.store.select(selectUser);

  }

  ngOnInit(): void {
    this.stripeServices.getSaldo(sessionStorage.getItem('email') as string).subscribe((res) => {
      if (res.data()) {
        this.saldo = res.data() as Saldo;
        this.saldoUsuario = Number(this.saldo.monto);
      }
    })

    this.userImgURL = sessionStorage.getItem('perfilURL') as string;
    this.portadaImgUrl = sessionStorage.getItem('portadaUrl') as string;
    this.form = new FormGroup({
      nombre: new FormControl({value: this.user.nombre, disabled: false}),
      apellido: new FormControl({value: this.user.apellido, disabled: false}),
      descripcion: new FormControl({value: this.user.descripcion, disabled: false}),
      facebook: new FormControl({value: this.user.facebook, disabled: false}),
      twitter: new FormControl({value: this.user.twitter, disabled: false}),
      instagram: new FormControl({value: this.user.instagram, disabled: false})
    });

    this.cardForm = this.formBuilder.group({
      nombre:['',[ Validators.minLength(1), Validators.required]],
      cardNumber:['',[ Validators.minLength(16), Validators.required]],
      clabe:['',[ Validators.minLength(18), Validators.required]],
    });

    this.cardServices.getCardData(sessionStorage.getItem('email') as string).subscribe((res) => {
      if (res.data()) {
      const cardData: CardData = res.data() as CardData;
      this.cardForm = this.formBuilder.group({
        nombre:[cardData.nombre as string,[ Validators.minLength(1), Validators.required]],
        cardNumber:[cardData.cardNumber,[ Validators.minLength(16), Validators.required]],
        clabe:[cardData.clabe,[ Validators.minLength(18), Validators.required]],
      });
      }
    })

    this.queryServices.getUsuario(sessionStorage.getItem('email') as string).subscribe((response) => {
      response.forEach((row) => {
      this.user = row.data() as Usuario;
      });
      this.form.controls['nombre'].setValue(this.user.nombre);
      this.form.controls['apellido'].setValue(this.user.apellido);
      this.form.controls['descripcion'].setValue(this.user.descripcion);
      this.form.controls['twitter'].setValue(this.user.twitter);
      this.form.controls['facebook'].setValue(this.user.facebook);
      this.form.controls['instagram'].setValue(this.user.instagram);
    });
  }

  validateOnlyNumbers($event: any, input: string) {
    const numberArray = "0123456789";
    const regex = new RegExp("[a-z]");
    if (regex.test($event.key)) {
      this.cardForm.controls[input].setValue(this.cardForm.controls[input].value.replaceAll($event.key,''));
    }
  }

  guardarCambios(): void {
    this.user.apellido = this.form.controls['apellido'].value;
    this.user.nombre = this.form.controls['nombre'].value;
    this.user.descripcion = this.form.controls['descripcion'].value;
    this.user.twitter = this.form.controls['twitter'].value;
    this.user.facebook = this.form.controls['facebook'].value;
    this.user.instagram = this.form.controls['instagram'].value;
    this.usuarioServices.guardarDatos(this.user).subscribe((response) => {
      this.queryServices.getUsuario(this.user.email).subscribe((res) => {
        res.forEach((row) => {
          this.store.dispatch(UserActions.saveUsuario({usuario: row.data() as Usuario}));
        });
      });
      Swal.fire({
        icon: 'success',
        title: 'Se ha actualizado el perfil',
        text: '',
        confirmButtonText: 'Continuar'
      });


    }, (err) => {
      console.log(err)
    });
  }


  seleccionarCategoria(id: number): void {
    if (this.user.categorias!.includes(id)) {
      this.user.categorias = this.user.categorias.filter(row => row != id);
    } else {
      this.user.categorias.push(id);
    }
  }

  subirImagen($event: any, fileDir: string, itemName: string){
    const file = $event.target.files[0];
    this.imagenServices.subirImagen(file, sessionStorage.getItem('email') as string, fileDir).subscribe((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Se ha actualizado el perfil',
        text: '',
        confirmButtonText: 'Continuar'
      });
      this.obtenerImagenPerfil(fileDir, itemName);
    });
  }


  obtenerImagenPerfil(fileDir: string, itemName: string):void{

    this.imagenServices.obtenerImagen(this.user.email, fileDir).subscribe((response)=>{
      switch (itemName) {
        case 'perfilURL':
          this.userImgURL = response;
          break;
        case 'portadaUrl':
          this.portadaImgUrl = response
        break;

        default:
          break;
      }

      sessionStorage.setItem(itemName, response);
    });
  }

  habilitarDonaciones() {

     const cardData: CardData = {
       nombre: this.cardForm.controls['nombre'].value,
       cardNumber: this.cardForm.controls['cardNumber'].value,
       clabe: this.cardForm.controls['clabe'].value
     }

     this.cardServices.saveCardData(cardData, this.user.email).subscribe((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Se ha actualizado su información',
        text: '',
        confirmButtonText: 'Continuar'
      });
     });

  }

  enviarEmails():void{
    this.enviarEmailSolicitudRetiroAdministracion();
    this.enviarEmailSolicitudRetiroUsuario();
  }

  enviarEmailSolicitudRetiroUsuario():void{
    const body = "<p>Estas solicitando un retiro de tu saldo</p>"+
                 "<p>Este proceso puede tardar hasta 72 horas, te pedimos paciencia.</p>";
    this.emailServices.enviarEmail("Solicitud de retiro", sessionStorage.getItem('email')as string, body).subscribe((result)=>{
    });
  }

  enviarEmailSolicitudRetiroAdministracion():void{
    const body = "<p>El usuario "+sessionStorage.getItem('email')as string+" está solicitando un retiro de su cuenta </p>"
    this.emailServices.enviarEmail("Solicitud de retiro", "pruebacreatopolis@gmail.com", body).subscribe((result)=>{
      Swal.fire({
        icon: 'success',
        title: 'Se ha solicitado el retiro de su saldo',
        text: '',
        confirmButtonText: 'Continuar'
      });
    });
  }


}
