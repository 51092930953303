import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { OpenComponent } from '../open/open.component';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {

  @Input()
  path = "";
  @Input()
  paginaPropia = false;
  @Output()
  emitEvent = new EventEmitter<boolean>();

  url!: string | ArrayBuffer| null;
  isVideo = false;

  constructor(private imagenServices: ImagenesService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.imagenServices.obtenerImagenByPath(this.path).subscribe((res) => {
      this.url = res;
      this.isVideo = this.url.includes('.video');
    });
  }

  open(): void {
    let config: MatDialogConfig = new MatDialogConfig();
    config.data = {url: this.url, paginaPropia: this.paginaPropia};
    const dialogRef = this.dialog.open<OpenComponent, {url: string, paginaPropia: boolean}>(OpenComponent, config);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.imagenServices.deleteImagen(this.url as string).subscribe((res) => {
          this.emitEvent.emit(true);
        })
      }
    });
  }


}
