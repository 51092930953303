
<div style="height: 250px;">
  <div class="credit-card" style="margin-top: 150px;">
    <div style="margin: auto;">
      <div  class="text-center" style="margin-bottom: 30px;">
        <p class="h5">¡Gracias por su donación!</p>
        <p *ngIf="monto != 0" class="h3">{{monto | currency}}</p>

        <form [formGroup]="form">
          <div class="input-group mb-3">
            <textarea type="text" class="form-control" name="" id="" placeholder="Mensaje" maxlength="150" formControlName="mensaje"></textarea>
          </div>
        </form>

        <form *ngIf="monto == 0" [formGroup]="form">
          <div class="input-group mb-3">
            <input class="form-control" placeholder="MXN"  type="text" appCurrencyMask  formControlName="mount" id="currency-number" style="border: 1px solid rgb(179, 179, 179);" >
          </div>
        </form>



      </div>

      <div #cardInfo style="margin-bottom: 30px; font-size: large"></div>
      <div class="error" style="margin-bottom: 30px; height: 30px;">
        <div  *ngIf="cardError">
          {{cardError}}
        </div>
      </div>
    </div>

    <div class="row col-12 d-flex justify-content-center">

      <div class="col-6">
        <button class="btn col-12" mat-button color="warn" (click)="pagar(false)">Cancelar</button>
      </div>

      <div class="col-6">
        <button class="btn institutional-button col-12" mat-button (click)="pagar(true)">Pagar</button>
      </div>

    </div>

  </div>



</div>






