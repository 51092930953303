import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from 'src/app/models/usuario';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { QueryServiceService } from 'src/app/services/query-service.service';
import { ShowsService } from 'src/app/services/shows.service';
import { AgregarAsistirComponent, ShowDialog } from './agregar-asistir/agregar-asistir.component';
import { Show } from 'src/app/models/show';
import { Location } from '@angular/common';


@Component({
  selector: 'app-asistir',
  templateUrl: './asistir.component.html',
  styleUrls: ['./asistir.component.css']
})
export class AsistirComponent implements OnInit {
  selected: Date | null | undefined;

  usuario: Usuario = {
    nombre: '',
    apellido: '',
    email: '',
    descripcion: '',
    rol: [],
    categorias: [],
    facebook: '',
    instagram: '',
    twitter: ''
  }

  showsList:ShowDialog[] = [];
  paginaPropia = false;
  userId = '';
  userImgURL:string = '';
  portadaImgUrl: string = '';
  horariosShow: string[] = [];
  imagenShow = '';
  tituloShow = '';
  fechaInicioShow = new Date();
  fechaFinalShow = new Date();

  constructor(private _location: Location, private showServices: ShowsService, private imagenServices: ImagenesService, private _Activatedroute: ActivatedRoute, private queryServices: QueryServiceService,  private router: Router, private dialog: MatDialog) {
   }

  ngOnInit(): void {

    this._Activatedroute.queryParams.subscribe((params) => {
      this.userId = atob(params['id']);
      this.paginaPropia = this.userId === sessionStorage.getItem('email') as string;
      this.getShows(this.userId);
      this.getUsuarioData(this.userId);
      this.getImagen(this.userId, 'portada');
      this.getImagen(this.userId, 'perfil');
    });
  }

  recargar(event: boolean) {
    this.getShows(this.userId);
  }


  backClicked() {
    this._location.back();
  }


  navegar(ruta: string, idUser: string):void{
    if (idUser) {
      this.router.navigate([ruta],
        { queryParams: { id: idUser } }
      );
    } else {
      this.router.navigate([ruta]);
    }
  }


  getImagen(userId: string, coleccion: string): void {
    this.imagenServices.obtenerImagen(userId, coleccion).subscribe((response) => {
      switch (coleccion) {
        case 'portada':
          this.portadaImgUrl = response;
          break;
        case 'perfil':
          this.userImgURL = response;
        break;
        default:
          break;
      }
    });
  }

  getUsuarioData(userId: string): void {
    this.queryServices.getUsuario(userId).subscribe((resp) => {
      resp.forEach((row) => {
        this.usuario = row.data() as Usuario;
      });
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open<AgregarAsistirComponent, Show>(AgregarAsistirComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveShow(result.dataDialog, result.imagen);
      }
    });
  }

  saveShow(data: ShowDialog, imagen:File):void{
    const show: Show = data.show;
    this.imagenServices.subirImagen(imagen,show.titulo.replaceAll('','').toLocaleUpperCase(), `show/${show.autor}`).subscribe((response)=>{
      show.urlImagen = response.metadata.fullPath;
      this.showServices.guardarShow(show, show.autor).subscribe((response)=>{
        this.getShows(this.userId);
      });
    });
  }

  getShows(userId: string): void {
    this.showServices.getShows(userId).subscribe((res) => {
      this.showsList = [];
      res.forEach((row) => {
        this.showsList.push({
           show: row.data() as Show,
           id: row.id,
           imgUrl:''
        }
        );
      });

      this.showsList.forEach((element)=>{
        this.imagenServices.obtenerImagenByPath(element.show.urlImagen).subscribe((response)=>{
          element.imgUrl = response;
        });
      });
    });
  }


  stringToDate(fecha:string):Date{
    return new Date(fecha);
  }

}
