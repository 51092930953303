
<div class="row" >

  <div *ngIf="data.paginaPropia" class="col-12 d-flex justify-content-center" style="height: 70vh;">
    <img [src]="data.url" *ngIf="data.url && !isVideo" style="height: 100%; width: 100%; object-fit:cover;">
    <video [src]="data.url" *ngIf="data.url && isVideo" controls style="height: 100%; width: 100%; object-fit:cover;"></video>
  </div>

  <div *ngIf="!data.paginaPropia" class="col-12 d-flex justify-content-center" style="height: 70vh;">
    <img [src]="data.url" *ngIf="data.url && !isVideo" style="height: 100%; width: 100%; object-fit:cover;">
    <video [src]="data.url" *ngIf="data.url && isVideo" controls style="height: 100%; width: 100%; object-fit:cover;"></video>
  </div>

  <div *ngIf="data.paginaPropia" class="col-12 text-center" style="padding-top: 2vh;">
    <button mat-button color="warn" (click)="onNoClick(true)">Eliminar</button>
  </div>

</div>
