import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { RegistroComponent } from './components/registro/registro.component';
import { CategoriasComponent } from './components/categorias/categorias.component';
import { PaginaPersonalComponent } from './components/pagina-personal/pagina-personal.component';
import { EditComponent } from './components/pagina-personal/edit/edit.component';
import { VerificacionComponent } from './components/login/verificacion/verificacion.component';
import { EditGuardia } from './guards/edit.guard';
import { DifundirComponent } from './components/difundir/difundir.component';
import { TiendaComponent } from './components/tienda/tienda.component';
import { AsistirComponent } from './components/asistir/asistir.component';
import { DonacionesComponent } from './components/donaciones/donaciones.component';
import { AvisoComponent } from './components/aviso/aviso.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { MainComponent } from './components/main/main.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {path: 'verificacion', component: VerificacionComponent},
  { path: 'registro', component: RegistroComponent },
  { path: 'explorar', component: CategoriasComponent },
  { path: 'mipagina', component: PaginaPersonalComponent },
  { path: 'creativo', component: PaginaPersonalComponent },
  {path: 'difundir', component:DifundirComponent},
  {path: 'asistir', component:AsistirComponent},
  { path: 'edit',canActivate:[EditGuardia], component: EditComponent },
  { path: 'edit', canActivate:[EditGuardia], component: EditComponent },
  { path: 'marketplace', component: TiendaComponent },
  { path: 'donar', component: DonacionesComponent },
  { path: 'aviso', component: AvisoComponent },
  {path: 'contacto', component: ContactoComponent},
  {path: '', component: MainComponent},
  {path: '**',redirectTo:'/'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
