
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { Usuario } from '../models/usuario';

import { Auth, User,  authState, createUserWithEmailAndPassword, deleteUser, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword } from '@angular/fire/auth';
import { Firestore, collection, doc, setDoc } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class UsuariosServicesService {

  constructor(private auth: Auth, private firestore: Firestore) {
  }

  public registrarCuenta( cuenta:string, password:string) {
    return from(createUserWithEmailAndPassword(this.auth,cuenta,password));
  }

  public enviarCorreoDeVerificacion(user:User){
    return from(sendEmailVerification(user));
  }

  public iniciarSesion(cuenta: string, password: string){
    return from (signInWithEmailAndPassword(this.auth,cuenta,password));
  }

  get userState$(){
    return authState(this.auth);
  }


  public cerrarSesion(){
    return from (this.auth.signOut());
  }

  public recuperarPassword(email: string){
    return from (sendPasswordResetEmail(this.auth, email));
  }


  public eliminarCuenta(user:User){
    return from(deleteUser(user));
  }

  public guardarDatos(user: Usuario){
    const referencia = doc(collection(this.firestore,'usuarios'), user.email);
    return from (setDoc(referencia,user));
  }

}
