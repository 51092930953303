

<div style="height: 80px;">

</div>

<div class="margin-2-rem">

  <div class="col-12 full-content "  *ngIf="destacado != ''">

    <img [src]="destacado"  autoplay>

  </div>

</div>

<div class="div-center text-center" *ngIf="!explorar">

  <div class="col-8 row d-flex justify-content-center" style="padding-bottom: 70px;">

    <div class="col-3">
      <div class="circle  musica-theme col-6 margin-auto" (click)="setExplorar(true, 1); setExplorationMode('listado');">
        <div >
          <img class="col-12" src="assets/icons/musica.svg" />
        </div>
      </div>
     <div class="margin-1-rem">
      <p class="h5">Música</p >
     </div>
    </div>

    <div class="col-3">
      <div class="circle cine-theme col-6 margin-auto" (click)="setExplorar(true, 2); setExplorationMode('listado');">
        <div >
          <img class="col-12" src="assets/icons/cine.svg" />
        </div>
      </div>
      <div class="margin-1-rem">
        <p class="h5">Cine</p >
       </div>
    </div>

    <div class="col-3">
      <div class="circle literatura-theme col-6 margin-auto" (click)="setExplorar(true, 3); setExplorationMode('listado');">
        <div >
          <img class="col-12" src="assets/icons/literatura.svg" />
        </div>
      </div>
      <div class="margin-1-rem">
        <p class="h5">Literatura</p >
       </div>
    </div>

    <div class="col-3">
      <div class="circle diseno-theme col-6 margin-auto" (click)="setExplorar(true, 4); setExplorationMode('listado');">
        <div >
          <img class="col-12" src="assets/icons/desing.svg" />
        </div>
      </div>
      <div class="margin-1-rem">
        <p class="h5">Diseño</p >
       </div>
    </div>

  </div>

  <div class="col-8 row d-flex justify-content-center">

    <div class="col-3">
      <div class="circle gastronomia-theme col-6 margin-auto" (click)="setExplorar(true, 5); setExplorationMode('listado');">
        <div >
          <img class="col-12" src="assets/icons/gastronomia.svg" />
        </div>
      </div>
      <div class="margin-1-rem">
        <p class="h5">Gastronomía</p >
       </div>
    </div>

    <div class="col-3">
      <div class="circle artesania-theme col-6 margin-auto" (click)="setExplorar(true, 6); setExplorationMode('listado');">
        <div >
          <img class="col-12" src="assets/icons/artesania.svg" />
        </div>
      </div>
      <div class="margin-1-rem">
        <p class="h5">Artesanía</p >
       </div>
    </div>



    <div class="col-3">
      <div class="circle arte-digital-theme col-6 margin-auto" (click)="setExplorar(true, 7); setExplorationMode('listado');">
        <div >
          <img class="col-12" src="assets/icons/arte_digital.svg" />
        </div>
      </div>
      <div class="margin-1-rem">
        <p class="h5">Arte digital</p >
       </div>
    </div>

  </div>

</div>

<div class="row col-11 col-lg-12 margin-auto" *ngIf="explorar">

  <div class="col-1">

  </div>

  <div class="col-11 row" *ngIf="explorar">

    <div class="col-4 d-flex justify-content-start">
      <div class="margin-1-rem">
        <p>
          Catálogo de creativos
        </p>
      </div>
    </div>

    <div class="col-4 d-flex justify-content-center">
      <form class="margin-1-rem" [formGroup]="form">
        <input formControlName="name" type="text" class="form-control width-300"  name="" id="" placeholder="Buscar creativo" (keyup)="findByCategorias(0)" style="">
      </form>
    </div>

   <div class="col-4 d-flex justify-content-end" *ngIf="explorar">
    <div class="explorer-menu-static margin-1-rem width-250 text-center gray-background border-radius-8" style="font-size: smaller !important;">
      <div class="margin-05-rem border-radius-8 cursos-pointer" [ngClass]="{'white-pink': explorationMode === 'mapa'}" (click)="setExplorationMode('mapa'); setExplorar(true, 0);">
        Mapa
      </div>
      <div class="margin-05-rem border-radius-8 cursos-pointer" [ngClass]="{'white-pink': explorationMode === 'listado'}" (click)="setExplorationMode('listado'); setExplorar(true, 0);">
        Listado
      </div>
      <div class="margin-05-rem border-radius-8 cursos-pointer" [ngClass]="{'white-pink': explorationMode === 'dividido'}" (click)="setExplorationMode('dividido'); setExplorar(true, 0);">
        Dividido
      </div>
     </div>
   </div>

  </div>

  <div class="text-center col-1">
    <div >
      <div>
        <!-- <div *ngIf="(categorias$ | async)!.categorias.includes(1)" class="circle-little-icon float-right" (click)="setExplorar(true, 1)">
          <mat-icon>close</mat-icon>
        </div> -->
        <div class="margin-1-rem div-center">
          <div class="circle-little musica-theme " [ngClass]="{'opacity-half': (!(categorias$ | async)!.categorias.includes(1) && (categorias$ | async)!.categorias.length != 0)}" (click)="setExplorar(true, 1)">
            <img class="small-icon" src="assets/icons/musica.svg" />
          </div>
        </div>
      </div>

      <div>
        <!-- <div *ngIf="(categorias$ | async)!.categorias.includes(2)" class="circle-little-icon float-right" (click)="setExplorar(true, 2)">
          <mat-icon>close</mat-icon>
        </div> -->
        <div class="margin-1-rem div-center">
          <div class="circle-little cine-theme" [ngClass]="{'opacity-half': (!(categorias$ | async)!.categorias.includes(2) && (categorias$ | async)!.categorias.length != 0)}" (click)="setExplorar(true, 2)">
            <img class="small-icon" src="assets/icons/cine.svg" />
          </div>
        </div>
      </div>

      <div>
        <!-- <div *ngIf="(categorias$ | async)!.categorias.includes(3)" class="circle-little-icon float-right" (click)="setExplorar(true, 3)">
          <mat-icon>close</mat-icon>
        </div> -->
        <div class="margin-1-rem div-center">
          <div class="circle-little literatura-theme" [ngClass]="{'opacity-half': (!(categorias$ | async)!.categorias.includes(3) && (categorias$ | async)!.categorias.length != 0)}" (click)="setExplorar(true, 3)">
            <img class="small-icon" src="assets/icons/literatura.svg" />
          </div>
        </div>
      </div>

      <div>
        <!-- <div *ngIf="(categorias$ | async)!.categorias.includes(4)" class="circle-little-icon float-right" (click)="setExplorar(true, 4)">
          <mat-icon>close</mat-icon>
        </div> -->
        <div class="margin-1-rem div-center">
          <div class="circle-little diseno-theme" [ngClass]="{'opacity-half': (!(categorias$ | async)!.categorias.includes(4) && (categorias$ | async)!.categorias.length != 0)}" (click)="setExplorar(true, 4)">
            <img class="small-icon" src="assets/icons/desing.svg" />
          </div>
        </div>
      </div>

      <div>
        <!-- <div *ngIf="(categorias$ | async)!.categorias.includes(5)" class="circle-little-icon float-right" (click)="setExplorar(true, 5)">
          <mat-icon>close</mat-icon>
        </div> -->
        <div class="margin-1-rem div-center">
          <div class="circle-little gastronomia-theme" [ngClass]="{'opacity-half': (!(categorias$ | async)!.categorias.includes(5) && (categorias$ | async)!.categorias.length != 0)}" (click)="setExplorar(true, 5)">
            <img class="small-icon" src="assets/icons/gastronomia.svg" />
          </div>
        </div>
      </div>

      <div>
        <!-- <div *ngIf="(categorias$ | async)!.categorias.includes(6)" class="circle-little-icon float-right" (click)="setExplorar(true, 6)">
          <mat-icon>close</mat-icon>
        </div> -->
        <div class="margin-1-rem div-center">
          <div class="circle-little artesania-theme" [ngClass]="{'opacity-half': (!(categorias$ | async)!.categorias.includes(6) && (categorias$ | async)!.categorias.length != 0)}" (click)="setExplorar(true, 6)">
            <img class="small-icon" src="assets/icons/artesania.svg" />
          </div>
        </div>
      </div>

      <div>
        <!-- <div *ngIf="(categorias$ | async)!.categorias.includes(7)" class="circle-little-icon float-right" (click)="setExplorar(true, 7)">
          <mat-icon>close</mat-icon>
        </div> -->
        <div class="margin-1-rem div-center">
          <div class="circle-little arte-digital-theme" [ngClass]="{'opacity-half': (!(categorias$ | async)!.categorias.includes(7) && (categorias$ | async)!.categorias.length != 0)}" (click)="setExplorar(true, 7)">
            <img class="small-icon" src="assets/icons/arte_digital.svg" />
          </div>
        </div>
      </div>

    </div>
  </div>


  <div class="col-11">
    <div *ngIf="explorationMode === 'mapa'">
      <app-mapa  [width]="'99%'" [height]="'700px'"></app-mapa>
    </div>

    <div *ngIf="explorationMode === 'listado'" class="col-12">
      <app-artistas [usuarios]="usuarios"></app-artistas>
    </div>

    <div *ngIf="explorationMode === 'dividido'" class="col-12 row">
      <div class="col-6 scroll">
        <app-artistas [usuarios]="usuarios" [isBig]="false"></app-artistas>
      </div>
      <div  class="col-6">
        <app-mapa  [width]="'99%'" [height]="'700px'"></app-mapa>
      </div>
    </div>
  </div>

</div>

