import { Injectable } from '@angular/core';
import { Firestore, collection, deleteDoc, doc, getDocs, query, setDoc, where } from '@angular/fire/firestore';
import { from } from 'rxjs';
import { Articulo } from '../models/articulo';
import { InsigniaUsuario } from '../models/insignia-usuario';


@Injectable({
  providedIn: 'root'
})
export class InsigniaService {

  constructor(private firestore: Firestore) { }

  public asignarInsignia(insigniaUsuario: InsigniaUsuario){
    const referencia = doc(collection(this.firestore,`insignias`));
    return from (setDoc(referencia, insigniaUsuario));
  }

  consultarInsigniasPorUsuario(email: string){
    return from (
      getDocs(
        query(collection(this.firestore, "insignias"), where("usuario", "==", email))
      )
    );
  }

}
