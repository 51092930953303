<div style="height: 130px;">

</div>



<div class="div-center" *ngIf="!userId">


  <div class="row col-10" style="padding-bottom: 100px;">
    <div class="col-3 d-flex justify-content-start" style="height: 19vw;">
      <img class="foto-perfil" *ngIf="!userImgURL" src="assets/icons/usuario.svg" />
      <img class="foto-perfil" *ngIf="userImgURL" [src]="userImgURL" alt="Imagen de perfil" />
    </div>

    <div class="col-4 marging-top-60 row">
      <div class="col-12">
        <div class="col-12">
          <p class="h1">{{(user$ | async)!.nombre}} {{(user$ | async)!.apellido}}</p>
        </div>

        <div class="col-12 row d-sm-none d-lg-flex" *ngIf="(user$ | async)!.rol.includes('1')">
          <div class="col-2" *ngFor="let categoria of (user$ | async)!.categorias">
            <img *ngIf="categoria == 1" class="circle col-12" src="assets/icons/musica.svg"/>
            <img *ngIf="categoria == 2" class="circle col-12" src="assets/icons/cine.svg"/>
            <img *ngIf="categoria == 3" class="circle col-12" src="assets/icons/literatura.svg"/>
            <img *ngIf="categoria == 4" class="circle col-12" src="assets/icons/desing.svg"/>
            <img *ngIf="categoria == 5" class="circle col-12" src="assets/icons/gastronomia.svg"/>
            <img *ngIf="categoria == 6" class="circle col-12" src="assets/icons/artesania.svg"/>
            <img *ngIf="categoria == 7" class="circle col-12" src="assets/icons/arte_digital.svg"/>
          </div>
        </div>

        <div class="col-12 row d-sm-none d-lg-flex" *ngIf="(user$ | async)!.rol.includes('2')">
          <p class="h5">Mecenas</p>
        </div>

        <div class="col-12 row d-sm-none d-lg-flex" *ngIf="(user$ | async)!.rol.includes('3')">
          <p class="h5">Fan</p>
        </div>

        <div class="col-11 margin-top-10">
          <h4>{{(user$ | async)!.descripcion}}</h4>
        </div>

        <div class="col-11 row margin-top-20 d-sm-none d-lg-flex justify-content-start">

          <div class="col-2" *ngIf="(user$ | async)!.instagram">
          <a href="{{(user$ | async)!.instagram}}">
            <img class="circle col-12" src="assets/icons/insta.svg"/>
          </a>
          </div>
          <div class="col-2" *ngIf="(user$ | async)!.twitter">
            <a  href="{{(user$ | async)!.twitter}}">
              <img class="circle col-12" src="assets/icons/twitter.svg"/>
            </a>
          </div>
          <div class="col-2" *ngIf="(user$ | async)!.facebook">
            <a  href="{{(user$ | async)!.facebook}}">
              <img class="circle col-12" src="assets/icons/facebook.svg"/>
            </a>
          </div>

        </div>
      </div>

    </div>

    <mat-tab-group class="col-5 margin-top-50 row">
      <mat-tab label="Opciones" class=" col-6" *ngIf="(user$ | async)!.rol.includes('1')">
        <div class="row col-12">
          <div class="col-6">
            <button class="btn institutional-button col-12 margin-05-rem" (click)="navegar('donar', email)" [disabled]="true">Donativos</button>
          </div>
          <div class="col-6">
            <button class="btn institutional-button col-12 margin-05-rem" (click)="navegar('marketplace', email)">Tienda</button>
          </div>
          <div class="col-6">
            <button class="btn institutional-button col-12 margin-05-rem" (click)="navegar('asistir',email)">Eventos</button>
          </div>
          <div class="col-6">
            <button class="btn institutional-button col-12 margin-05-rem" (click)="navegar('difundir',email)">Campañas</button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Insignias" class=" col-6">
        <div class="col-12 row" *ngIf="insigniasList.length != 0">
          <div  class="col-4" *ngFor="let insignia of insigniasList">
            <img class="col-12 circle" *ngIf="insignia" [src]="insignia" />
          </div>

        </div>
      </mat-tab>
    </mat-tab-group>

  </div>


</div>

<div class="div-center" *ngIf="userId && usuario!.rol.includes('1')" >


  <div class="row col-10" style="padding-bottom: 100px;">
    <div class="col-3 d-flex justify-content-start" style="height: 19vw;" >
      <img class="foto-perfil" *ngIf="!userImgURL" src="assets/icons/usuario.svg" />
      <img class="foto-perfil" *ngIf="userImgURL" [src]="userImgURL" alt="Imagen de perfil" />
    </div>



    <div class="col-4 marging-top-60">

      <div class="col-12">
        <p class="h3">{{usuario.nombre}} {{usuario.apellido}}</p>
      </div>

      <div class="col-12 row d-sm-none d-lg-flex">
        <div class="col-2" *ngFor="let categoria of usuario.categorias">
          <img *ngIf="categoria == 1" class="circle col-12" src="assets/icons/musica.svg"/>
          <img *ngIf="categoria == 2" class="circle col-12" src="assets/icons/cine.svg"/>
          <img *ngIf="categoria == 3" class="circle col-12" src="assets/icons/literatura.svg"/>
          <img *ngIf="categoria == 4" class="circle col-12" src="assets/icons/desing.svg"/>
          <img *ngIf="categoria == 5" class="circle col-12" src="assets/icons/gastronomia.svg"/>
          <img *ngIf="categoria == 6" class="circle col-12" src="assets/icons/artesania.svg"/>
          <img *ngIf="categoria == 7" class="circle col-12" src="assets/icons/arte_digital.svg"/>
        </div>
      </div>
      <div class="col-8 margin-top-10">
        <h4>{{usuario.descripcion}}</h4>
      </div>

      <div class="col-11 row margin-top-20 d-sm-none d-lg-flex justify-content-start">

        <div class="col-2" *ngIf="usuario.instagram">
        <a  href="{{usuario.instagram}}" target="_blank">
          <img class="circle col-12" src="assets/icons/insta.svg"/>
        </a>
        </div>
        <div class="col-2" *ngIf="usuario.twitter">
          <a href="{{usuario.twitter}}" target="_blank">
            <img class="circle col-12" src="assets/icons/twitter.svg"/>
          </a>
        </div>
        <div class="col-2" *ngIf="usuario.facebook">
          <a href="{{usuario.facebook}}" target="_blank">
            <img class="circle col-12" src="assets/icons/facebook.svg"/>
          </a>
        </div>

      </div>

    </div>

    <div class="col-5">
      <div class="row margin-top-100">
        <div class="col-6">
          <button class="btn institutional-button col-12 margin-05-rem" (click)="navegar('donar', email)" [disabled]="!((user$ | async)!.rol.includes('1') || (user$ | async)!.rol.includes('2')) || !donacionHabilitada">Donar</button>
        </div>
        <div class="col-6">
          <button class="btn institutional-button col-12 margin-05-rem" (click)="navegar('marketplace', email)">Comprar</button>
        </div>
        <div class="col-6">
          <button class="btn institutional-button col-12 margin-05-rem" (click)="navegar('asistir',email)">Asistir</button>
        </div>
        <div class="col-6">
          <button class="btn institutional-button col-12 margin-05-rem" (click)="navegar('difundir',email)">Difundir</button>
        </div>
      </div>
    </div>

  </div>

</div>

<div class="div-center">

  <div class="row col-10 d-flex justify-content-center" >
    <div *ngFor="let item of pathList" class="col-lg-4 col-md-10">
      <app-post [path]="item" [paginaPropia]="paginaPropia"  (emitEvent)="recargarPost($event)"></app-post>
    </div>
  </div>

  <div class="col-10 d-flex justify-content-center" style="padding-top: 20px; padding-bottom: 40px;" *ngIf="!userId && (user$ | async)!.rol.includes('2')">
    <div class="col-10">

      <div class="row col-12 panel-header" (click)="expandir('donaciones')">
        <div class="col-6 margin-auto" >
          Donaciones
        </div>
        <div class="col-6 d-flex justify-content-end margin-auto">
          <button mat-icon-button>
          <mat-icon class="mat-icon-rtl-mirror">
            {{donacionesIsExpanded ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>

        </div>
      </div>

      <div *ngIf="donacionesIsExpanded" class="row col-12 text-center panel-body">

        <div class="col-12 padding-40">

         <app-artistas [usuarios]="usuariosDonados"></app-artistas>
        </div>

      </div>

      <div class="row col-12 panel-header" (click)="expandir('compras')">
        <div class="col-6 margin-auto" >
          Compras
        </div>
        <div class="col-6 d-flex justify-content-end margin-auto">
          <button mat-icon-button>
          <mat-icon class="mat-icon-rtl-mirror">
            {{comprasIsExpanded ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>

        </div>
      </div>

      <div *ngIf="comprasIsExpanded" class="row col-12 text-center panel-body">
        <div class="margin-auto">
          <h2>Proximamente</h2>
        </div>
      </div>



      <div class="row col-12 panel-header" (click)="expandir('eventos')">
        <div class="col-6 margin-auto" >
          Eventos Asistidos
        </div>
        <div class="col-6 d-flex justify-content-end margin-auto">
          <button mat-icon-button>
          <mat-icon class="mat-icon-rtl-mirror">
            {{eventIsExpanded ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>

        </div>
      </div>

      <div *ngIf="eventIsExpanded" class="row col-12 text-center panel-body">
        <div class="margin-auto">
          <h2>Proximamente</h2>
        </div>

      </div>

      <div class="row col-12 panel-header" (click)="expandir('difundidos')">
        <div class="col-6 margin-auto" >
          Difundido
        </div>
        <div class="col-6 d-flex justify-content-end margin-auto">
          <button mat-icon-button>
          <mat-icon class="mat-icon-rtl-mirror">
            {{difundidoIsExpanded ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>

        </div>
      </div>

      <div *ngIf="difundidoIsExpanded" class="row col-12 text-center panel-body">
        <div class="margin-auto">
          <h2>Proximamente</h2>
        </div>

      </div>


    </div>
  </div>


</div>








