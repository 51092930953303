import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { UsuariosServicesService } from 'src/app/services/usuarios.services.service';
import { User } from '@angular/fire/auth';
import { Usuario } from 'src/app/models/usuario';
import { InsigniaService } from 'src/app/services/insignia.service';
import { InsigniaUsuario } from 'src/app/models/insignia-usuario';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css'],

})
export class RegistroComponent implements OnInit {

  enEspera = false;

  //Puede cambiar
  private readonly formBuilder = inject(FormBuilder);
  private readonly patronEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  soyCreativo = false;
  form: FormGroup = new FormGroup({});
  categoriasSeleccionadas: number[] = [];

  constructor(private usuarioServices: UsuariosServicesService, private router: Router, private store: Store, private insigniaServices: InsigniaService) {

    }

  ngOnInit(): void {
    this.inicializarForm();

  }

  activarSoyCreativo(): void {
    if(this.soyCreativo) {
      this.soyCreativo = false;
    } else {
      this.soyCreativo = true;
    }
  }

  registrarCuenta(): void {
    if (this.form.controls['password'].value === this.form.controls['passwordConfirm'].value) {
      this.enEspera = true;
      this.usuarioServices.registrarCuenta(this.form.controls['email'].value,this.form.controls['password'].value
      ).subscribe((respuesta) => {
        const {user} =  respuesta;
        this.registrarUsuario(user);
    });
    }
  }


  registrarUsuario(cuentaRegistrada: User): void {
    console.log(this.form.controls['tipoUsuario'].value as number)
    const rol: string[] = [this.form.controls['tipoUsuario'].value];
    let user: Usuario = {
      nombre: this.form.controls['nombre'].value,
      apellido: this.form.controls['apellido'].value,
      email: this.form.controls['email'].value,
      descripcion: this.form.controls['descripcion'].value,
      rol: [...rol],
      categorias: (this.form.controls['tipoUsuario'].value) === '1' ? this.categoriasSeleccionadas.sort() : [],
      facebook: '',
      instagram: '',
      twitter: ''
    };
    user.rol = rol;
    console.log(user);

    this.usuarioServices.guardarDatos(user).subscribe((response)=>{
      this.usuarioServices.enviarCorreoDeVerificacion(cuentaRegistrada).subscribe((responseECV)=>{
        this.enEspera = false;
      this.openDialog();
      const insigniaFundador: InsigniaUsuario = {
        insignia: 'founder.png',
        usuario: this.form.controls['email'].value,
        date: new Date()
      }
      this.insigniaServices.asignarInsignia(insigniaFundador).subscribe((res) => {});
      });
    },(error)=>{
      this.enEspera = false;
      this.usuarioServices.eliminarCuenta(cuentaRegistrada).subscribe((responseEC)=>{
      });
    }
    );
  }

  openDialog(): void {
    Swal.fire({
      title: '¡Felicidades ' + this.form.controls['nombre'].value +', ya eres parte de Creatópolis!',
      icon: 'success',
      html:
      "Se ha enviado un correo para validar su cuenta" +

      "<div class='firework' id='firework1'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>" +
    "<div class='firework' id='firework2'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>" +
    "<div class='firework' id='firework3'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>" +

    "<div class='firework' id='firework4'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>" +
    "<div class='firework' id='firework5'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>" +
    "<div class='firework' id='firework6'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>" +
    "<div class='firework' id='firework7'>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
        "<div class='celebration explosion'></div>" +
    "</div>",
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> Ir a Explorar',
      confirmButtonAriaLabel: 'Ir a Explorar',
    }).then((result) => {
      this.router.navigate(['/explorar']);
    })

  }



  seleccionarCategoria(id: number): void {
    if (this.categoriasSeleccionadas.includes(id)) {
      this.categoriasSeleccionadas = this.categoriasSeleccionadas.filter(row => row != id);
    } else {
      this.categoriasSeleccionadas.push(id);
    }
  }


    //Puede cambiar
   inicializarForm():void{
    this.form = this.formBuilder.group({
      email:['',[Validators.required,Validators.pattern(this.patronEmail)]],
      nombre:['',[Validators.required]],
      apellido:['',[Validators.required]],
      tipoUsuario:['',Validators.required],
      password:['',[ Validators.minLength(8), Validators.required]],
      passwordConfirm:['',[Validators.minLength(8),Validators.required]],
      descripcion:['',[Validators.required]]

    });
  }

  validarForm(): boolean {
    if (this.form.controls['tipoUsuario'].value as number == 1) {
      return this.form.valid && this.categoriasSeleccionadas.length != 0 && this.form.controls['password'].value === this.form.controls['passwordConfirm'].value;
    } else {
      return this.form.valid && this.form.controls['password'].value === this.form.controls['passwordConfirm'].value;
    }
  }


}
