<div style="height: 50px;">

</div>

<div class="position-relative col-12 div-center">
  <div class="position-absolute portada">
    <img *ngIf="portadaImgUrl" [src]="portadaImgUrl"/>

  </div>

  <div class="return" (click)="backClicked()">
    <a>Regresar</a>
  </div>



  <div class="position-absolute col-11 portada-padding">


    <div class="col-lg-12 col-md-11 justify-content-center">

      <div class="row d-flex justify-content-center">
        <div class="col-lg-2 col-md-5 div-center-on-small" style="height: 14vw;">
          <img class="foto-perfil" *ngIf="!userImgURL" src="assets/icons/usuario.svg" />

            <img class="foto-perfil" *ngIf="userImgURL" [src]="userImgURL" alt="Imagen de perfil"/>

        </div>

        <div class="col-lg-4 col-md-5">

          <div>
            <p class="h2" style="color: white;">{{usuario.nombre}} {{usuario.apellido}}</p>
          </div>
          <div class="row col-8">
            <div class="col-md-2 col-lg-3" *ngFor="let categoria of usuario.categorias">
              <img *ngIf="categoria == 1" class="circle col-12" src="assets/icons/musica.svg"/>
              <img *ngIf="categoria == 2" class="circle col-12" src="assets/icons/cine.svg"/>
              <img *ngIf="categoria == 3" class="circle col-12" src="assets/icons/literatura.svg"/>
              <img *ngIf="categoria == 4" class="circle col-12" src="assets/icons/desing.svg"/>
              <img *ngIf="categoria == 5" class="circle col-12" src="assets/icons/gastronomia.svg"/>
              <img *ngIf="categoria == 6" class="circle col-12" src="assets/icons/artesania.svg"/>
              <img *ngIf="categoria == 7" class="circle col-12" src="assets/icons/arte_digital.svg"/>
            </div>
          </div>


          <div class="row col-8" style="padding-top: 20px;">

            <div class="col-lg-2 col-md-2" *ngIf="usuario.instagram">
             <a href="{{usuario.instagram}}">
              <img class="circle col-12" src="assets/icons/insta.svg"/>
             </a>
            </div>
            <div class="col-lg-2 col-md-2" *ngIf="usuario.twitter">
              <a href="{{usuario.twitter}}">
                <img class="circle col-12" src="assets/icons/twitter.svg"/>
              </a>
            </div>
            <div class="col-lg-2 col-md-2" *ngIf="usuario.facebook">
              <a href="{{usuario.facebook}}">
                <img class="circle col-12" src="assets/icons/facebook.svg"/>
              </a>
            </div>

          </div>

        </div>

        <div class="col-lg-5 d-flex justify-content-end col-md-1">


        </div>

      </div>

    </div>


    <div class="row col-12" style="margin-top: 80px;">

      <div class="col-12" style="margin-bottom: 50px;">
        <p class="h3">
          ¿Cuánto deseas donar?
        </p>
      </div>

      <div class="col-3 d-flex justify-content-center">
        <div class="col-9 d-flex justify-content-center box row" (click)="openDialog(100)">
         <div class="text-center">
          <p class="h5">Donar</p>
          <p class="h2">$100</p>
         </div>
        </div>
      </div>

      <div class="col-3 d-flex justify-content-center">
        <div class="col-9 d-flex justify-content-center box row" (click)="openDialog(500)">
         <div class="text-center">
          <p class="h5">Donar</p>
          <p class="h2">$500</p>
         </div>
        </div>
      </div>

      <div class="col-3 d-flex justify-content-center">
        <div class="col-9 d-flex justify-content-center box row" (click)="openDialog(1000)">
         <div class="text-center">
          <p class="h5">Donar</p>
          <p class="h2">$1000</p>
         </div>
        </div>
      </div>

      <div class="col-3 d-flex justify-content-center">
        <div class="col-9 d-flex justify-content-center box row" (click)="openDialog(0)">
         <div class="text-center">
          <p class="h5">Donar</p>
          <p class="h2">Personalizar</p>
         </div>
        </div>
      </div>

      <div style="height: 80px;">

      </div>


  </div>



</div>
