import { AfterViewInit, Component, ElementRef, OnDestroy, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-donacion',
  templateUrl: './donacion.component.html',
  styleUrls: ['./donacion.component.css']
})
export class DonacionComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('cardInfo')
  cardInfo!: ElementRef;
  cardError: string = '';
  card: any;
  form: FormGroup;

  constructor( private ngZone: NgZone, public dialogRef: MatDialogRef<DonacionComponent>,  @Inject(MAT_DIALOG_DATA) public monto: number, fb:FormBuilder) {
    this.form=fb.group({
      mensaje: '',
      mount:['']
    })
   }

  ngOnDestroy(): void {
    this.card.destroy();
  }

  ngOnInit(): void {
  }

  async onChange({error}: any) {
    this.ngZone.run(() => {

    console.log(error)
      this.cardError = '';
      if (error){
        console.log(error.message);
        this.cardError = error.message;
      }
      });

  }

  ngAfterViewInit(): void {
    this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.onChange.bind(this));
  }

  async pagar(val: boolean) {
    if (val) {
      const {token, error} = await stripe.createToken(this.card);

      if (token) {
        console.log(this.form.controls['mount'].value);
        this.dialogRef.close({token: token, monto: this.form.controls['mount'].value === '' ? this.monto : this.form.controls['mount'].value.replace('$','') as number, mensaje: this.form.controls['mensaje'].value});
      } else {
        this.ngZone.run(() => {
          this.cardError = error.message;
        });
      }
    } else {
      this.dialogRef.close(val);
    }

  }

}
