import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Direccion } from 'src/app/models/direccion';
import { Usuario } from 'src/app/models/usuario';
import { QueryServiceService } from 'src/app/services/query-service.service';
import { UsuariosServicesService } from 'src/app/services/usuarios.services.service';
import { CategoriasActios } from '../utils/reduce/categorias/categorias.actions';
import { Categoria } from '../utils/reduce/categorias/categoria';
import { Observable } from 'rxjs';
import { selectCaregorias } from '../utils/reduce/categorias/categoria.selectors';
import { FormControl, FormGroup } from '@angular/forms';
import { ImagenesService } from 'src/app/services/imagenes.service';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css']
})
export class CategoriasComponent implements OnInit {

  @Input()
  explorar = false;

  categorias$!: Observable<Categoria>;

  @Input()
  explorationMode = ""

  usuarios: Usuario[] = [];

  direcciones: Direccion[] = [];

  buscar = false;

  form: FormGroup = new FormGroup({});

  cats: number[] = [];

  searchName = '';
  destacado = '';




  constructor(private usuarioServices: UsuariosServicesService, private queryServices: QueryServiceService, private store: Store, private imageServices: ImagenesService) {
    this.categorias$ = this.store.select(selectCaregorias);
   }

  ngOnInit(): void {

    this.imageServices.obtenerImagen('barnner.gif', 'destacados').subscribe((res) => {
      console.log(res);
      this.destacado = res;
    })

    this.form = new FormGroup({
      name: new FormControl('')
    });

    this.store.dispatch(CategoriasActios.setCategorias({categoria: -1, nombre: ''}));
    this.buscar = false;
    this.buscarCreativos();
  }

  setExplorar(value: boolean, categoria: number) {
    this.buscar = true;
    this.explorar = value;
    if (value) {
      this.findByCategorias(categoria);
    }
  }

  setExplorationMode(value: string) {
    this.explorationMode = value;
  }

  findByCategorias(categoria: number) {
    console.log(this.form.controls['name'].value === '' ? '' : this.form.controls['name'].value)
    this.store.dispatch(CategoriasActios.setCategorias({categoria: categoria, nombre: this.form.controls['name'].value === '' ? '' : this.form.controls['name'].value}));

  }


  filtrarCreativosPorNombre(nombre: string) {
    this.usuarios = this.usuarios.filter((us) => (us.nombre.toUpperCase().includes(nombre.toUpperCase()) || us.apellido.toUpperCase().includes(nombre.toUpperCase())));
  }

  buscarCreativos(): void {
this.usuarios = [];
    this.categorias$.subscribe((cats) =>  {
      this.usuarios = [];
      if (cats.categorias.length > 0 && this.buscar) {
        this.cats = cats.categorias;
        this.searchName = cats.nombre;
        this.queryServices.getAllCreativosByCategoria(cats.categorias).subscribe((response) => {
          this.usuarios = [];
          response.forEach((row) => {
            this.usuarios.push(row.data() as Usuario);
          });
          this.filtrarCreativosPorNombre(this.searchName);
        });

       } else if (this.buscar) {
        this.queryServices.getAllCreativos().subscribe((response) => {
          this.usuarios = [];
          response.forEach((row) => {
            this.usuarios.push(row.data() as Usuario);
          });
          this.filtrarCreativosPorNombre(this.searchName);
        });
       }

    });
  }


}
