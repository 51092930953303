<div style="height: 50px;">

</div>
<div class="position-relative col-12 div-center">
    <div class="position-absolute portada">
      <img *ngIf="portadaImgUrl" [src]="portadaImgUrl"/>

    </div>


    <div class="return" (click)="backClicked()">
      <a>Regresar</a>
    </div>

    <div class="position-absolute col-11 portada-padding">

      <div class="col-lg-12 col-md-11 justify-content-center" style="padding-bottom: 3rem;">

        <div class="row d-flex justify-content-center">
          <div class="col-lg-2 col-md-5 div-center-on-small" style="height: 14vw;">
            <img class="foto-perfil" *ngIf="!userImgURL" src="assets/icons/usuario.svg" />

            <img class="foto-perfil" *ngIf="userImgURL" [src]="userImgURL" alt="Imagen de perfil"/>
          </div>

          <div class="col-lg-4 col-md-5">

            <div>
              <p class="h2" style="color: white;">{{usuario.nombre}} {{usuario.apellido}}</p>
            </div>
            <div class="row col-8">
              <div class="col-md-2 col-lg-3" *ngFor="let categoria of usuario.categorias">
                <img *ngIf="categoria == 1" class="circle col-12" src="assets/icons/musica.svg"/>
                <img *ngIf="categoria == 2" class="circle col-12" src="assets/icons/cine.svg"/>
                <img *ngIf="categoria == 3" class="circle col-12" src="assets/icons/literatura.svg"/>
                <img *ngIf="categoria == 4" class="circle col-12" src="assets/icons/desing.svg"/>
                <img *ngIf="categoria == 5" class="circle col-12" src="assets/icons/gastronomia.svg"/>
                <img *ngIf="categoria == 6" class="circle col-12" src="assets/icons/artesania.svg"/>
                <img *ngIf="categoria == 7" class="circle col-12" src="assets/icons/arte_digital.svg"/>
              </div>
            </div>


            <div class="row col-8" style="padding-top: 20px;">

              <div class="col-lg-2 col-md-2" *ngIf="usuario.instagram">
               <a href="{{usuario.instagram}}" target="_blank">
                <img class="circle col-12" src="assets/icons/insta.svg"/>
               </a>
              </div>
              <div class="col-lg-2 col-md-2" *ngIf="usuario.twitter">
                <a href="{{usuario.twitter}}" target="_blank">
                  <img class="circle col-12" src="assets/icons/twitter.svg"/>
                </a>
              </div>
              <div class="col-lg-2 col-md-2" *ngIf="usuario.facebook">
                <a href="{{usuario.facebook}}" target="_blank">
                  <img class="circle col-12" src="assets/icons/facebook.svg"/>
                </a>
              </div>

            </div>

          </div>

          <div class="col-lg-5 d-flex justify-content-end col-md-1">
            <div>
              <button *ngIf="paginaPropia" (click)="openDialog()" mat-raised-button class="" color="accent" aria-label="Agregar artículo">
                <mat-icon>add</mat-icon>
              </button>
            </div>

          </div>

        </div>

      </div>

      <div style="padding-bottom: 50px;">
        <p class="h5">Asiste a los eventos de {{usuario.nombre}} {{usuario.apellido}}</p>
      </div>

      <div class="col-12 justify-content-center" *ngFor="let show of showsList">

        <app-panel [show]="show" (outPutEvent)="recargar($event)"></app-panel>

      </div>

      <div *ngIf="showsList.length == 0" class="text-center margin-auto">
        <p class="h5">No se han encontrado eventos</p>
      </div>
      <div style="height: 120px;">

      </div>
    </div>

</div>
