import { createReducer, on } from '@ngrx/store';

import { UserActions } from './auth.actions';
import { Usuario } from 'src/app/models/usuario';

export const initialState: Usuario = {
  nombre: '',
  apellido: '',
  email: '',
  descripcion: '',
  rol: [''],
  categorias: [0],
  facebook: '',
  instagram: '',
  twitter: ''
};

export const usuarioSaveReducer = createReducer(
  initialState,
  on(UserActions.saveUsuario, (_state, newState) => ({
    ...newState.usuario
  }) )
);

