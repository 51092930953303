import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImagenesService } from 'src/app/services/imagenes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subir-post',
  templateUrl: './subir-post.component.html',
  styleUrls: ['./subir-post.component.css']
})
export class SubirPostComponent implements OnInit {

  file!: File;
  url!: string | ArrayBuffer| null;
  format = "";
  waithing = true;
  constructor(private imagenServices: ImagenesService, public dialogRef: MatDialogRef<SubirPostComponent>) { }

  ngOnInit(): void {
  }

  onNoClick(value: boolean): void {
    if (value) {
      this.waithing = true;
      this.savePost(this.file, this.format);
    } else {
      this.dialogRef.close(value);
    }
  }

  subirImagen($event: any) {
    this.file = $event.target.files[0];
    if (this.file) {
      var reader = new FileReader();
      reader.readAsDataURL(this.file);
      if(this.file.type.indexOf('image')> -1){
        this.format = 'image';
      } else if(this.file.type.indexOf('video')> -1){
        this.format = 'video';
      }
      reader.onload = (event) => {
        this.url = (<FileReader>event.target).result;
        this.waithing = false;
      }
    }
  }


  savePost(file: File, format: string):void {
    this.imagenServices.subirImagen(file, `${(new Date().getTime()).toString()}.${format}`, `post/${sessionStorage.getItem('email')!.toUpperCase()}`).subscribe((res) => {
      this.dialogRef.close(true);
    }, (error) => {
      this.dialogRef.close(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Al parecer ha ocurrido un problema, si persiste, contacte con soporte'
      });
    });

  }

}
