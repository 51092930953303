<header >

  <div class="d-flex header flex-wrap justify-content-between py-3 mb-4 shadow-box-1 background-color-white-1" style="padding: 0px !important;">

    <div>
      <div class="display-inline-block div-center margin-1-rem">
        <img  src="assets/icons/creatopolis-beta.png" style="width: 193.3px; height: 39.3; margin-left: 10%;"/>
      </div>
      <!-- <div class="display-inline-block div-center vertical-center">
        <a class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
          <span class=" " style="color: rgb(70, 70, 70); font-weight: bold;">CREATOPOLIS</span>
        </a>
      </div> -->
    </div>

    <div class="vertical-center aling-right">
      <ul class="nav nav-pills">
        <li class="nav-item"><a  class="nav-link" aria-current="page" routerLink="/">La plataforma</a></li>
        <li class="nav-item"><a  routerLink="/explorar" class="nav-link">Explorar</a></li>
        <li class="nav-item" *ngIf="!(user$ | async)!.nombre"><a  routerLink="/login" class="nav-link">Iniciar sesión</a></li>
        <button mat-button class="nav-link nav-item" [matMenuTriggerFor]="menu" *ngIf="(user$ | async)!.nombre">Perfil</button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item routerLink="/mipagina">Mi página</button>
          <button mat-menu-item routerLink="/edit">Editar perfil</button>
          <button mat-menu-item (click)="openDialog()" *ngIf="(user$ | async)!.rol.includes('1')">Crear post</button>
          <button mat-menu-item (click)="cerrarSesion()">Cerrar sesión</button>
        </mat-menu>
        <li class="nav-item"><a routerLink="/contacto" class="nav-link">Contacto</a></li>
      </ul>
    </div>
  </div>
</header>
