import { Component, OnInit } from '@angular/core';
import { UsuariosServicesService } from 'src/app/services/usuarios.services.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Usuario } from 'src/app/models/usuario';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserActions } from '../utils/reduce/auth.actions';
import { QueryServiceService } from 'src/app/services/query-service.service';
import { ImagenesService } from 'src/app/services/imagenes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: FormGroup = new FormGroup({});

  restablecer = false;

  constructor(private usuarioServices: UsuariosServicesService, private router: Router, private store: Store, private queryServices: QueryServiceService, private imagenServices: ImagenesService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl(''),
      password: new FormControl('')
    });
  }

  login() {
   this.validarUsuarioVerificado(this.form.controls['email'].value,this.form.controls['password'].value)
  }

  restablecerPassword(){
    this.usuarioServices.recuperarPassword(this.form.controls['email'].value);
  }

  setRestablecer(value : boolean): void{
    this.restablecer = value;
  }



  validarUsuarioVerificado(email:string, password:string):void{


    this.usuarioServices.iniciarSesion(email, password).subscribe((response)=>{
      const {user} = response;
      this.queryServices.getUsuario(email).subscribe((res) => {
        sessionStorage.setItem("email", email);
        res.forEach((row) => {
          this.store.dispatch(UserActions.saveUsuario({usuario: row.data() as Usuario}));
        });
        this.obtenerImagenPerfil(email, user.emailVerified);
        this.navegar(user.emailVerified
          ?'usuario'
          :'verificacion');
      });
    },(error)=>{
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Parece que tu cuenta o contraseña son incorrectos',
        confirmButtonText: 'Intentar de nuevo',
        // footer: '<a href="">Why do I have this issue?</a>'
      });

    });

  }

  obtenerImagenPerfil(email: string, verificado: boolean):void{

    this.imagenServices.obtenerImagen(email, 'portada').subscribe((response)=>{
      sessionStorage.setItem('portadaUrl', response == null ? '' : response);

    });

    this.imagenServices.obtenerImagen(email, 'perfil').subscribe((response)=>{
      sessionStorage.setItem('perfilURL', response == null ? '' : response);
    });




  }

  navegar(ruta: string):void{
    this.router.navigate([ruta]);
  }

}
