

<div class="row d-flex justify-content-md-center justify-content-lg-start" *ngIf="isBig">

  <div class="col-md-10 col-lg-3 " *ngFor="let usuario of usuarios.slice(firstElement, lastElement)">
    <div class="resume-card col-12 background-color-white-1 shadow-box-1">
      <app-resume-card [usuario]="usuario"></app-resume-card>
    </div>
  </div>

  <mat-paginator [length]="usuarios.length"
              [pageSize]="elementsPerPage"
              (page)="handlePageEvent($event)"
              [pageIndex]="0"
              aria-label="Select page">
</mat-paginator>


</div>


<div class="row d-flex justify-content-md-center justify-content-lg-start scroll" *ngIf="!isBig">

  <div class="col-md-10 col-lg-6" *ngFor="let usuario of usuarios.slice(firstElement, lastElement)">
    <div class="resume-card col-12 background-color-white-1 shadow-box-1">
      <app-resume-card [usuario]="usuario"></app-resume-card>
    </div>
  </div>
</div>

<div class="row d-flex justify-content-md-center justify-content-lg-start" *ngIf="!isBig">

  <mat-paginator [length]="usuarios.length"
              [pageSize]="elementsPerPage"
              (page)="handlePageEvent($event)"
              [pageIndex]="0"
              aria-label="Select page">
  </mat-paginator>


</div>
