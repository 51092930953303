<div class="row width-700">

  <div class="col-12 d-flex justify-content-center">
    <p class="h5">
      Nuevo artículo
    </p>
  </div>

  <form [formGroup]="form">

    <div class="col-12 d-flex justify-content-center  margin-05-rem">

      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input type="text" matInput placeholder="Producto" value="" formControlName="nombre">
      </mat-form-field>

    </div>

    <div class="col-12 d-flex justify-content-center  margin-05-rem">

      <mat-form-field>
        <mat-label>Link de compra</mat-label>
        <input type="url" matInput placeholder="URL" value="" formControlName="link" >
      </mat-form-field>

    </div>

    <div class="col-12 d-flex justify-content-center  margin-05-rem" >

      <mat-form-field>
        <mat-label>Precio</mat-label>
        <input type="number" matInput placeholder="MXN" value="" formControlName="precio">
      </mat-form-field>

    </div>

  </form>
  <div>

    <div *ngIf="image" class="d-flex justify-content-center">
      <div class="imagen">
        <img class=" img" [src]="image" alt="Imagen de perfil" />
      </div>
    </div>

    <div *ngIf="image ===''" class="d-flex justify-content-center">
      <input hidden type="file" #uploadImg accept=".png, .jpg , .jpeg"  (change)="subirImagen($event)">
      <button mat-button color="primary" aria-label="Agregar foto" (click)="uploadImg.click()">Cargar imagen</button>
    </div>

  </div>

  <div class="col-12  d-flex justify-content-center" style="height: 36px;">
    <input hidden type="file" #uploadImg accept=".png, .jpg , .jpeg"  (change)="subirImagen($event)">
    <button  *ngIf="image !==''" mat-button color="primary" aria-label="Agregar foto" (click)="uploadImg.click()">Cargar imagen</button>
  </div>

  <div class="col-12 d-flex justify-content-center">
    <div class="">
      <button mat-button color="warn" (click)="onNoClick(false)">Cancelar</button>
    </div>

    <div class="">
      <button mat-raised-button color="accent" [disabled]="form.invalid || image === ''" (click)="onNoClick(true)">Guardar</button>
    </div>
  </div>

</div>
